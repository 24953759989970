import { Block } from './utils/block';
import { Context } from './context/context';
import { DeviceOnPort } from './device/deviceportbase';
import { handleBlocks } from './handlers/handlers';
import { initMotorPairMovementPair } from './handlers/motorpair';
import { processOperation } from './handlers/operator';
import { HelperEnabledRegistryPayload } from './context/helpers';
import { ImportRegistryPayload } from './context/imports';
import { ProcedureRegistryPayload } from './context/procedures';
import PyConverterOptions from './pyconverteroptions';
import {
    BlockField,
    ScratchBlock,
    ScratchProject,
    ScratchTarget,
} from './utils/scratch';
import { INDENT, get_divider, indent_code } from './utils/utils';
import { VariableRegistryPayload } from './context/variables';

enum StackGroupType {
    Start = 1,
    Event = 2,
    MessageEvent = 3,
    MyBlock = 4,
    Orphan = 9,
}

interface StackGroup {
    opcode: string;
    shortname: string;
    group: StackGroupType;
    stack: Block[];
    writeAccessVariables: VariableRegistryPayload[];
}

type OutputCodeStack = {
    id: string | undefined;
    code: string[] | undefined;
    isStartup?: boolean;
    isDivider?: boolean;
    name?: string;
    group?: StackGroupType;
};

export default class PyConverter {
    _options: PyConverterOptions;

    constructor(options: PyConverterOptions = {}, readonly context = new Context()) {
        this._options = options;
    }

    convert(projectData: ScratchProject) {
        return this.convertProject(projectData);
    }

    convertProject(projectData: ScratchProject) {
        // TODO: CHECK: context.reset()
        if (!projectData.targets || projectData.targets.length < 2) {
            return;
        }

        this.preprocessMessages(projectData.targets[0]);
        const retval = this.convertMainTarget(projectData.targets[1]);
        return retval;
    }

    convertMainTarget(target1: ScratchTarget) {
        // ========================
        let plaincode, pycode;
        try {
            this.preprocessProcedureDefinitions(target1);
            this.preprocessVariables(target1);

            // ------------------------
            const topLevelStacks = this.prepareTopLevelStacks(target1);

            // ------------------------
            plaincode = this.generatePlainCodeForStacks(topLevelStacks);

            // ------------------------
            const stackGroups = this.getStackGroups(topLevelStacks);
            this.preprocessStackGroups(stackGroups);

            // switch to async if there ar multiple start stacks or any event stack
            if (!this.context.isAsyncNeeded) {
                this.context.isAsyncNeeded =
                    (stackGroups.get(StackGroupType.Start)?.length ?? 0) > 1 ||
                    (stackGroups.get(StackGroupType.Event)?.length ?? 0) > 0 ||
                    (stackGroups.get(StackGroupType.MessageEvent)?.length ?? 0) > 0 ||
                    false;
            }

            const programStacks = this.getPycodeForStackGroups(stackGroups);
            const programCode = this.createProgramStacksCode(programStacks) || [];
            const setupCode = this.createSetupCodes();
            const helperCode = HelperEnabledRegistryPayload.to_global_code(
                this.context.helpers,
            );
            const mainProgramCode = this.createMainProgramCode(programStacks);

            const codeSections: { name: string; code?: string[]; skip?: boolean }[] = [
                {
                    name: 'imports',
                    code: ImportRegistryPayload.to_global_code(this.context.imports),
                    skip: this._options?.debug?.skipImports,
                },
                {
                    name: 'helper functions',
                    code: helperCode,
                    skip: this._options?.debug?.skipHelpers,
                },
                {
                    name: 'setup',
                    code: setupCode,
                    skip: this._options?.debug?.skipSetup,
                },
                {
                    name: 'global variables',
                    code: VariableRegistryPayload.to_global_code(
                        this.context.variables,
                    ),
                    skip: this._options?.debug?.skipVariableDeclarations,
                },
                { name: 'program code', code: programCode },
                { name: 'main code', code: mainProgramCode },
            ];

            const retval2 = codeSections
                .filter((curr) => !curr.skip)
                .map((curr) =>
                    curr.code?.length
                        ? [
                              get_divider(
                                  `SECTION: ${curr.name.toUpperCase()}`,
                                  'region',
                                  '=',
                              ),
                              ...curr.code,
                              '#endregion',
                          ].join('\r\n')
                        : null,
                );

            pycode = retval2.filter((e) => e).join('\r\n\r\n');
        } catch (err) {
            console.error('::ERROR::', err);
        }

        return {
            pycode,
            plaincode,
        };
    }

    private preprocessVariables(target1: ScratchTarget) {
        if (!target1.variables) {
            return;
        }
        if (!target1.lists) {
            return;
        }

        for (const varblock of Object.values(target1.variables)) {
            if (Array.isArray(varblock)) {
                const name = varblock[0];
                // respect the non-list type to avoid collision
                this.context.variables.use([name, false], null, false);
            }
        }
        for (const varblock of Object.values(target1.lists)) {
            if (Array.isArray(varblock)) {
                const name = varblock[0];
                // respect the list type to avoid collision
                this.context.variables.use([name, true], null, true);
            }
        }
    }

    generatePlainCodeForStacks(topLevelStacks: Block[][]) {
        const genSimpleCodeForStack = (
            blocks: Block[],
            doIndentFirst = true,
        ): string[] => {
            return blocks
                ?.map((block, index) => {
                    const code: string[] = [
                        (!doIndentFirst || index > 0 ? INDENT : '') +
                            block.getDescription(false),
                    ];
                    block.substacks?.map((substack, substackindex) => {
                        const substackCode = genSimpleCodeForStack(
                            substack,
                            false,
                        )?.map((line) => INDENT + line);
                        if (substackindex > 0) {
                            code.push(INDENT + '^^^');
                        }
                        if (substackCode) {
                            code.push(...substackCode);
                        }
                    });

                    return code;
                })
                .flat();
        };
        const genSimpleCodeForStacks = (stacks: Block[][]) => {
            return stacks
                .map((stack) => genSimpleCodeForStack(stack, true))
                .map((slines) => [...slines, ''])
                .flat();
        };

        const code = genSimpleCodeForStacks(topLevelStacks);
        return code.join('\n');
    }

    createSetupCodes() {
        const setupCodes = [];
        setupCodes.push('hub = PrimeHub()');
        this.context.imports.use('pybricks.hubs', 'PrimeHub');

        // ensure dependencies
        for (const elem of this.context.devicesRegistry.values()) {
            elem.ensureDependencies();
        }

        // process and add all devices
        const remainingItems = [...this.context.devicesRegistry.values()];

        // anything that is connected to a port
        Array.from(
            remainingItems.filter(
                (elem): elem is DeviceOnPort => elem instanceof DeviceOnPort,
            ),
        )
            .sort((a: DeviceOnPort, b: DeviceOnPort) =>
                a.portString.localeCompare(b.portString),
            )
            .forEach((elem) => {
                const code = elem.setupCode();
                if (code) {
                    setupCodes.push(...code);
                }

                const idx = remainingItems.indexOf(elem);
                remainingItems.splice(idx, 1);
            });

        // any remaining items
        remainingItems
            .sort((a, b) => a.devicename?.localeCompare(b?.devicename ?? '') ?? 0)
            .forEach((elem) => {
                const code = elem.setupCode();
                if (code) {
                    setupCodes.push(...code);
                }
            });

        // add default speeds
        if (this.context.deviceDefaultSpeeds.size) {
            const default_speeds = Array.from(
                this.context.deviceDefaultSpeeds.entries(),
            ).map(([devicename, speed]) => `${devicename}: ${speed}`);
            setupCodes.push(`default_speeds = {${default_speeds.join(', ')}}`);
        }

        return setupCodes;
    }

    preprocessMessages(target0: ScratchTarget) {
        if (!target0.broadcasts) {
            return;
        }

        Object.entries(target0.broadcasts).forEach(([id, name]) =>
            this.context.broadcasts.use(id, name),
        );
    }

    preprocessProcedureDefinitions(target1: ScratchTarget) {
        if (!target1.blocks) {
            return;
        }

        Object.entries(target1.blocks)
            .filter(([_, sblock]) => sblock.opcode === 'procedures_definition')
            .forEach(([id, sblock]) => {
                const block = new Block(sblock, id, target1, this);
                const procdef = ProcedureRegistryPayload.create(block);
                if (procdef) {
                    this.context.procedures.use(procdef.id, procdef);
                }
            });
    }

    createProgramStacksCode(programStacks: OutputCodeStack[]): string[] {
        const stacks = Array.from(programStacks.values()).filter(
            (ostack) => ostack.code,
        );
        return stacks?.length > 0
            ? stacks
                  .filter(
                      (ostack) =>
                          !this._options?.debug?.showThisStackOnly ||
                          ostack.id === this._options?.debug?.showThisStackOnly,
                      // && (this._options?.debug?.showOrphanCode || ostack.group !== StackGroupType.Orphan)
                  )
                  .map((ostack) =>
                      ostack.code && ostack.code.length > 0 && !ostack.isDivider
                          ? [
                                this._options.debug?.showBlockIds
                                    ? `# BlockId: "${ostack.id}"`
                                    : null,
                                ...ostack.code,
                                '',
                            ]
                          : ostack.code
                          ? // divider or empty
                            [...ostack.code]
                          : [],
                  )
                  .flat()
                  .filter((line) => line !== undefined && line !== null)
            : [];
    }

    createMainProgramCode(ostacks: OutputCodeStack[]) {
        const startupStacks = ostacks.filter((ostack) => ostack.isStartup);

        if (ostacks.length === 0 || startupStacks.length === 0) {
            return [
                '# no startup stacks registered, program will not do anything',
                this.context.isAsyncNeeded ? 'yield' : 'pass',
            ];
        }

        if (this.context.isAsyncNeeded) {
            this.context.imports.use('pybricks.tools', 'multitask, run_task');

            // multiple start stacks
            return [
                'async def main():',
                indent_code([
                    `await multitask(${[
                        ...startupStacks.map((ostack) => `${ostack.name}()`),
                    ].join(', ')})`,
                ])[0],
                'run_task(main())',
            ];
        } else {
            // single start stack
            return [`${startupStacks[0].name}()`];
        }
    }

    getPycodeForStackGroups(
        stackGroups: Map<StackGroupType, StackGroup[]>,
    ): OutputCodeStack[] {
        let stackCounter = 0;
        const aggregatedCodeStacks: OutputCodeStack[] = [];

        for (const [group_name, stack_group] of stackGroups.entries()) {
            if (
                group_name === StackGroupType.Orphan &&
                !this._options?.debug?.showOrphanCode
            ) {
                continue;
            }

            // add a header code
            const groupNameStr = StackGroupType[group_name];
            aggregatedCodeStacks.push({
                id: undefined,
                code: [get_divider(`GROUP: ${groupNameStr.toUpperCase()}`, '', '-')],
                isStartup: false,
                isDivider: true,
                group: group_name,
            });

            let lastStackEventMessageId = undefined;
            const aggregatedMessageFns: string[] = [];
            for (const stack_gitem of stack_group) {
                try {
                    stackCounter++;

                    const code: string[] = [];
                    const currentStack = stack_gitem.stack;
                    const group = stack_gitem.group;
                    const headBlock = currentStack[0];
                    const nextBlocks = currentStack.slice(1);

                    let stack_fn = `stack${stackCounter}_${stack_gitem.shortname}_fn`;
                    const stackActionFn = `stack${stackCounter}_action_fn`;
                    let description = headBlock.getDescription();
                    let funcSignature = `${stack_fn}()`;

                    const messageRecord = this.getMessageRecord(currentStack);
                    const messageId = messageRecord?.[1];
                    const messageNameRaw = messageRecord?.[0]?.toString();
                    // const messageName = sanitize(messageNameRaw);
                    const isMessageChanged = lastStackEventMessageId !== messageId;
                    lastStackEventMessageId = this.checkAndRegisterMessage(
                        currentStack,
                        stackActionFn,
                        lastStackEventMessageId,
                        aggregatedCodeStacks,
                        aggregatedMessageFns,
                        false,
                    );

                    if (group === StackGroupType.MyBlock) {
                        const functionDef = ProcedureRegistryPayload.create(
                            headBlock,
                            true,
                        );
                        //procedures.use(functionDef.id, functionDef);
                        // Procedures.register(functionDef);

                        if (!functionDef) {
                            return [];
                        }
                        stack_fn = functionDef.getPyName('myblock_');
                        funcSignature = functionDef.getPyDefinition();
                        description = funcSignature;
                    } else if (group === StackGroupType.MessageEvent) {
                        if (isMessageChanged) {
                            aggregatedCodeStacks.push({
                                id: undefined,
                                code: [
                                    get_divider(`MESSAGE: ${messageNameRaw}`, '', '-'),
                                ],
                                isStartup: false,
                                isDivider: true,
                                group,
                            });
                        }
                    }

                    code.push(`# STACK #${stackCounter}: ${description}`);
                    const comment = this.getCommentForBlock(headBlock);
                    if (comment) {
                        code.push(`# ${comment}`);
                    }

                    const sub_code = this.process_stack(
                        nextBlocks,
                        this._options?.debug?.showExplainingComments,
                    );
                    switch (group_name) {
                        case StackGroupType.Start:
                        case StackGroupType.MyBlock:
                            {
                                code.push(
                                    `${
                                        this.context.isAsyncNeeded ? 'async def' : 'def'
                                    } ${funcSignature}:`,
                                );

                                // list global variables with write access
                                const variablesForWriteAccess = currentStack.reduce(
                                    (aggr, curr) => {
                                        curr.variablesForWriteAccess.forEach((elem) =>
                                            aggr.add(elem),
                                        );
                                        return aggr;
                                    },
                                    new Set<VariableRegistryPayload>(),
                                );
                                if (variablesForWriteAccess.size) {
                                    code.push(
                                        ...indent_code(
                                            `global ${Array.from(
                                                variablesForWriteAccess,
                                            )
                                                .map((value) => value.py)
                                                .join(', ')}`,
                                        ),
                                    );
                                }

                                code.push(...sub_code);

                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: group_name === StackGroupType.Start,
                                    name: stack_fn,
                                    isDivider: false,
                                    group,
                                });
                            }
                            break;
                        case StackGroupType.Event:
                            {
                                // case 'flipperevents_whenButton': // TODO: later separate and optimize
                                // case 'flipperevents_whenTimer': // TODO: later separate and optimize

                                // stack action function
                                code.push(`async def ${stackActionFn}():`);
                                code.push(...sub_code);

                                // condition function
                                const stack_cond_fn = `stack${stackCounter}_condition_fn`;
                                const condition_code = processOperation.call(
                                    this,
                                    headBlock,
                                );
                                code.push(`async def ${stack_cond_fn}():`);
                                code.push(
                                    ...indent_code(['return ' + condition_code.raw]),
                                );
                                code.push(`async def ${stack_fn}():`);
                                code.push(
                                    ...indent_code([
                                        `await ${
                                            this.context.helpers
                                                .use('event_task')
                                                ?.call(stack_cond_fn, stackActionFn).raw
                                        }`,
                                    ]),
                                );

                                // add to stack
                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: true,
                                    name: stack_fn,
                                    group,
                                });
                            }
                            break;

                        case StackGroupType.MessageEvent:
                            {
                                // const messageName = getMessageName(currentStack);

                                // stack action function
                                code.push(`async def ${stackActionFn}():`);
                                code.push(...sub_code);

                                // condition function already added once on top

                                // add to stack
                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: false,
                                    group,
                                });
                            }
                            break;

                        default:
                            {
                                if (!this._options?.debug?.showOrphanCode) {
                                    continue;
                                }

                                code.push(
                                    '### this code has no hat block and will not be running',
                                );
                                const sub_code = this.process_stack(currentStack).map(
                                    (line) => '# ' + line,
                                );
                                code.push(...sub_code);

                                // add to stack, but not to startup
                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: false,
                                    group,
                                });
                            }
                            break;
                    }
                } catch (err) {
                    console.error('::ERROR::', err);
                }
            }

            // dump any potential accumulated message
            this.checkAndRegisterMessage(
                undefined,
                undefined,
                lastStackEventMessageId,
                aggregatedCodeStacks,
                aggregatedMessageFns,
                true,
            );
        }

        return aggregatedCodeStacks;
    }

    checkAndRegisterMessage(
        currentStack: Block[] | undefined,
        stackActionFn: string | undefined,
        lastStackEventMessageId: string | undefined,
        aggregatedCodeStacks: OutputCodeStack[] | undefined,
        aggregatedMessageFns: string[],
        forceDump: boolean,
    ) {
        if (!currentStack) {
            return;
        }
        if (!aggregatedCodeStacks) {
            return;
        }

        const messageRecord = this.getMessageRecord(currentStack);
        const messageId = messageRecord?.[1] ?? undefined;
        if (
            aggregatedMessageFns.length &&
            (lastStackEventMessageId !== messageId || forceDump)
        ) {
            const bco = this.context.broadcasts.get(lastStackEventMessageId);

            this.context.helpers.use('class_Message');
            const message_fn = bco?.get_pyname();
            aggregatedCodeStacks.push({
                id: message_fn,
                code: bco ? [bco.get_code(aggregatedMessageFns)] : [],
                isStartup: false,
            });
            const stack_fn = `${message_fn}.main_fn`;
            aggregatedCodeStacks.push({
                id: undefined,
                code: undefined,
                isStartup: true,
                name: stack_fn,
            });

            aggregatedMessageFns.splice(0, aggregatedMessageFns.length);
            lastStackEventMessageId = messageId;
        }

        if (messageId?.length) {
            if (!this.context.broadcasts.has(messageId)) {
                const messageName = messageRecord?.[0]?.toString();
                this.context.broadcasts.use(messageId, messageName);
            }
            if (stackActionFn) {
                aggregatedMessageFns.push(stackActionFn);
            }
            lastStackEventMessageId = messageId;
        }

        return lastStackEventMessageId;
    }

    prepareTopLevelStacks(target1: ScratchTarget): Block[][] {
        if (!target1.blocks) {
            return [];
        }
        return Object.entries(target1.blocks)
            .filter(([, sblock]) => sblock.topLevel && !sblock.shadow)
            .sort((a, b) => {
                return (
                    Math.floor((a[1]?.y ?? 0) / 600) -
                        Math.floor((b[1].y ?? 0) / 600) ||
                    Math.floor((a[1]?.x ?? 0) / 400) - Math.floor((b[1].x ?? 0) / 400)
                );
            })
            .map(([id, sblock]) => {
                return this.prepareStack(sblock, id, target1);
            });
    }

    prepareStack(sblock: ScratchBlock, id: string, root: ScratchTarget): Block[] {
        const block = new Block(sblock, id, root, this);
        return Block.buildStack(block);
    }

    getStackGroups(topLevelStacks: Block[][]) {
        const retval = topLevelStacks
            .map((stack) => {
                const headBlock = stack[0];
                const op = headBlock.opcode;
                function get_op_group(op: string) {
                    let m: RegExpMatchArray | null;
                    if (
                        (m = op.match(
                            /^(?:flipper|horizontal)events_(whenProgramStarts)/,
                        ))
                    ) {
                        return [m[1], StackGroupType.Start];
                    } else if (
                        (m = op.match(
                            /^event_when(broadcast)received|horizontalevents_when(Broadcast)/,
                        ))
                    ) {
                        return [m[1], StackGroupType.MessageEvent];
                    } else if (
                        (m = op.match(/^(?:flipper|horizontal)events_(when.*)/))
                    ) {
                        return [m[1], StackGroupType.Event];
                        // } else if (
                        //   (m = op.match(/^radiobroadcast_(whenIReceiveRadioSignal)Hat/))
                        // ) {
                        //   return [m[1], StackGroupType.Event];
                    } else if ((m = op.match(/^(procedures_definition)/))) {
                        return [m[1], StackGroupType.MyBlock];
                    } else {
                        return [null, StackGroupType.Orphan];
                    }
                }
                const groupids = get_op_group(op);
                return {
                    opcode: op,
                    shortname: groupids[0]?.toString().toLowerCase(),
                    group: groupids[1],
                    stack: stack,
                    writeAccessVariables: [],
                } as StackGroup;
            })
            .sort((a, b) => a.group.valueOf() - b.group.valueOf())
            .reduce((output, item) => {
                const key = item['group'];
                const values = output.get(key) || [];
                //if (!output.has(key)) output.set(key, []);
                values.push(item);
                output.set(key, values);
                return output;
            }, new Map<StackGroupType, StackGroup[]>());

        if (retval.get(StackGroupType.MessageEvent)) {
            retval.get(StackGroupType.MessageEvent)?.sort(
                (a: StackGroup, b: StackGroup) =>
                    this.getMessageRecord(a?.stack)?.[0]
                        ?.toString()
                        ?.localeCompare(
                            this.getMessageRecord(b?.stack)?.[0]?.toString() ?? '',
                        ) ?? 0,
            );
        }

        return retval;
    }

    preprocessStackGroups(stackGroups: Map<StackGroupType, StackGroup[]>) {
        let motorpairFound = false;
        for (const [stack_type, stack_group] of stackGroups.entries()) {
            if (stack_type === StackGroupType.Orphan) {
                continue;
            }
            for (const stack of stack_group) {
                for (const block of stack.stack) {
                    const op = block.opcode;
                    if (op === 'flippermove_setMovementPair' && !motorpairFound) {
                        initMotorPairMovementPair
                            .call(this, block, undefined, true)
                            .ensureDependencies();
                        motorpairFound = true;
                        // only one/first setMovementPair is taken into account
                    }
                    if (op === 'flippercontrol_fork') {
                        this.context.isAsyncNeeded = true;
                    }
                }
            }
        }

        // only if no setMovementPair is used
        if (!motorpairFound) {
            initMotorPairMovementPair
                .call(this, undefined, ['A', 'B'], false)
                .ensureDependencies();
        }
    }

    process_stack(blocks: Block[] | null, showExplainingComments?: boolean): string[] {
        const retval: string[] = [];

        if (blocks && blocks.length > 0) {
            for (const block of blocks) {
                const comment = this.getCommentForBlock(block);
                if (comment) {
                    retval.push(...indent_code(`# ${comment}`));
                }

                if (showExplainingComments) {
                    const pseudoLine = block.getDescription(false);
                    retval.push(...indent_code(['', `# ${pseudoLine}`]));
                }

                const sub_code = this.convertBlockToCode(block);
                if (sub_code !== null) {
                    retval.push(...indent_code(sub_code));
                }
            }
        } else {
            retval.push(...indent_code(this.context.isAsyncNeeded ? 'yield' : 'pass'));
        }

        return retval;
    }

    getMessageRecord(stack: Block[]): BlockField | undefined {
        const headBlock = stack?.[0];
        if (headBlock?.opcode === 'event_whenbroadcastreceived') {
            // [0] is the message name, [1] is the message refid
            return headBlock.getFieldObject('BROADCAST_OPTION');
        } else if (headBlock?.opcode === 'horizontalevents_whenBroadcast') {
            // "CHOICE": [
            //   1,
            //   "zFLqW+b*f2b]lG6nUD/."
            // ]
            const eventcolor = headBlock.get('CHOICE')?.toString();
            // let the eventcolor be the message id as well
            return [eventcolor, eventcolor];
        } else {
            return;
        }
    }

    getCommentForBlock(block: Block) {
        const comment = block._block.comment
            ? block._root.comments?.[block._block.comment]?.text
            : undefined;
        return comment?.replace(/[\r\n]/g, ' ');
    }

    convertBlockToCode(block: Block): string[] | null {
        try {
            const retval = handleBlocks.call(this, block);
            if (retval) {
                return retval;
            }
        } catch (e) {
            console.trace(e);
            return [`# error with: ${block.getDescription()} @ ${block._id} - ${e}`];
        }

        // _debug('unknown block', block.get_block_description());
        return [`# Unknown: ${block.getDescription()}`, 'pass'];
    }
}
