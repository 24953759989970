import { Context } from './context';
import { RegistryManager } from './registrymanager';
import { sanitize } from '../utils/utils';

export class BroadcastRegistryPayload {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    get_code(functions: string[]) {
        return `${this.get_pyname()} = Message([${functions.join(', ')}])`;
    }

    get_pyname() {
        return `message_${sanitize(this.name)}`;
    }

    static createRegistry(context: Context) {
        return new RegistryManager(
            context,
            (...args: unknown[]) => new BroadcastRegistryPayload(args[0] as string),
        );
    }
}
