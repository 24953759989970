import JSZip from 'jszip';
// import context, { Context } from './context';
import PyConverter from './pyconverter';
import PyConverterOptions from './pyconverteroptions';
import { ProjectInfo, ScratchProject } from './utils/scratch';

export interface PyProjectResult {
    pycode?: string;
    plaincode?: string;
    projectInfo?: ProjectInfo;
    svg?: string;
    project?: ScratchProject;
}

export async function convertFlipperProjectToPython(
    filedata: ArrayBuffer | Buffer,
    options: PyConverterOptions,
): Promise<PyProjectResult> {
    const retval = {
        pycode: undefined,
        plaincode: undefined,
        projectInfo: undefined,
        svg: undefined,
        project: undefined,
    } as PyProjectResult;
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(filedata);
    if (!zipContent) {
        throw 'Error loading the zip file.';
    }

    // ========================
    const manifestFile = zipContent.file('manifest.json');
    if (!manifestFile) {
        throw 'No manifest.json file found in the zip file.';
    }
    const manifestContent = await manifestFile.async('string');
    const projectInfo = JSON.parse(manifestContent);
    if (
        projectInfo.type &&
        !['word-blocks', 'icon-blocks'].includes(projectInfo.type)
    ) {
        throw `File type should be word-blocks instead of "${projectInfo.type}"`;
    }
    retval.projectInfo = projectInfo;

    const projectComment = !options?.debug?.skipHeader
        ? extractHeadComment(projectInfo)
        : null;

    // ========================
    {
        const filename = 'icon.svg';
        const file = zipContent.file('icon.svg');
        if (!file) {
            throw `No ${filename} file found in the zip file.`;
        }
        retval.svg = await file.async('string');
    }

    // ========================
    const scratch_file = await zipContent.file('scratch.sb3');
    if (!scratch_file) {
        throw new Error('Missing scratch.sb3');
    }
    const scratch_data = (await scratch_file?.async('arraybuffer')) as ArrayBuffer;
    const sb3zip = await zip.loadAsync(scratch_data);
    const projectFile = sb3zip.file('project.json');
    if (!projectFile) {
        throw new Error('Missing project.json');
    }
    const projectData = await projectFile.async('text');
    const projectJson = JSON.parse(projectData);
    retval.project = projectJson;

    // ========================
    //context.contextApi.run(new Context(), () => {
    const codes = new PyConverter(options).convert(projectJson);
    if (codes) {
        const sections = [projectComment, codes.pycode].filter((elem) => elem);
        retval.pycode = sections.join('\n');
        retval.plaincode = codes.plaincode;
    }
    //});

    return retval;
}

function extractHeadComment(projectInfo: ProjectInfo) {
    return `
"""
Project:    ${projectInfo.name}
Slot:       ${projectInfo.slotIndex}
Created:    ${projectInfo.created}
Last saved: ${projectInfo.lastsaved}

"""
`.trim();
}
