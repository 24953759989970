export enum OperatorPrecedence {
    SIMPLE = 0, // Simple value or procedure call
    PARENTHESIS = 1, // (expressions...), [expressions...], {key: value...}, {expressions...} Binding or parenthesized expression, list display, dictionary display, set display
    REFERENCE = 2, // x[index], x[index:index], x(arguments...), x.attribute Subscription, slicing, call, attribute reference
    EXPONENTIATION = 3, // ** Exponentiation [5]
    UNARY = 4, // +x, -x, ~x Positive, negative, bitwise NOT
    BINARYOP_MUL = 5, // *, @, /, //, % Multiplication, matrix multiplication, division, floor division, remainder [6]
    BINARY_ADD = 6, // +, - Addition and subtraction
    BINARY_SHIFT = 7, // <<, >> Shifts
    BINARY_AND = 8, // & Bitwise AND
    BINARY_XOR = 9, // ^ Bitwise XOR
    BINARY_OR = 10, // | Bitwise OR
    BINARY_COMPARISON = 11, // in, not in, is, is not, <, <=, >, >=, !=, == Comparisons, including membership tests and identity tests
    BOOLEAN_NOT = 12, // not x Boolean NOT
    BOOLEAN_AND = 13, // and Boolean AND
    BOOLEAN_OR = 14, // or Boolean OR
    WEAKEST = 99,
}

export enum ValueType {
    UNKNOWN,
    NUMBER,
    STRING,
    BOOLEAN,
    OTHER, //enum, array
}
