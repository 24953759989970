import { Block } from '../utils/block';
import { BlockValue, num_eval } from '../utils/blockvalue';
import { calc_stop } from '../utils/converters';
import { DeviceDriveBase } from '../device/drivebase';
import { ValueType } from '../utils/enums';
import PyConverter from '../pyconverter';
import {
    CONST_CM,
    CONST_DEGREES,
    CONST_INCHES,
    CONST_ROTATIONS,
    CONST_SECONDS,
    indent_code,
    isNumber,
} from '../utils/utils';

function _get_stop_fn(this: PyConverter, device: DeviceDriveBase) {
    switch (device.get_then()) {
        case 'Stop.COAST':
            return `${this.context.awaitPrefix}${device.devicename}.stop()`;
        case 'Stop.HOLD':
            return `${this.context.awaitPrefix}${device.devicename}.hold()`;
        case 'Stop.BRAKE':
        default:
            return `${this.context.awaitPrefix}${device.devicename}.brake()`;
    }
}

type FlipperMoveParams = {
    unit?: BlockValue;
    value?: BlockValue;
    direction?: string;
    steer?: BlockValue;
    speed?: BlockValue;
    power?: BlockValue;
    left_speed?: BlockValue;
    right_speed?: BlockValue;
    left_power?: BlockValue;
    right_power?: BlockValue;
};

function _process_move_with_steering(
    this: PyConverter,
    params: FlipperMoveParams,
    waiting_goal?: string[],
): string[] {
    const device = DeviceDriveBase.instance(this.context) as DeviceDriveBase;
    const d = device.devicename;
    const stop_fn = _get_stop_fn.call(this, device);
    let start_motor_code: string[] = [];

    if (params.direction !== undefined || params.steer !== undefined) {
        let steer1: BlockValue | number = 0,
            negate_speed = false;
        if (params.direction !== undefined) {
            if (
                params.direction === 'clockwise' ||
                params.direction === 'counterclockwise'
            ) {
                /* spin turn */
                steer1 = params.direction === 'clockwise' ? 100 : -100;
            } else if (params.direction === 'forward' || params.direction === 'back') {
                negate_speed = params.direction === 'back';
                steer1 = 0;
            }
        } else if (params.steer !== undefined) {
            // NOTE: steer is sometimes a number sometimes label gets stuck 'straight: 0' - need to parse it
            if (
                !params.steer.options?.is_dynamic &&
                params.steer.toString()?.match(/[a-z]+: ([-]?\d+)/)
            ) {
                steer1 = new BlockValue(
                    parseInt(params.steer.toString().split(': ')[1]),
                );
            } else {
                steer1 = params.steer.ensureNumber(this.context);
            }
        }

        // ------------
        if (params.power === undefined) {
            /* STEER-SPEED mode with optional value */
            let speed1: BlockValue | undefined =
                params.speed !== undefined
                    ? params.speed
                    : new BlockValue(device.default_speed_variable, {
                          is_dynamic: true,
                          type: ValueType.NUMBER,
                      });
            if (negate_speed) speed1 = num_eval(this.context, ['-', speed1]);

            start_motor_code = [
                this.context.helpers
                    .use('motorpair_move')
                    .call(
                        device.motor_left?.devicename,
                        device.motor_right?.devicename,
                        steer1,
                        speed1,
                    )
                    .toString(),
            ];
        } else {
            if (negate_speed)
                params.power = num_eval(this.context, ['-', params.power]);

            /* STEER-POWER mode with mandatory value */
            start_motor_code = [
                this.context.helpers
                    .use('motorpair_move_dc')
                    .call(
                        device.motor_left?.devicename,
                        device.motor_right?.devicename,
                        steer1,
                        params.power,
                    )
                    .toString(),
            ];
        }
    } else if (params.left_speed !== undefined && params.right_speed !== undefined) {
        /* TANK-SPEED mode */
        start_motor_code = [
            `${device.motor_left?.devicename}.run(${params.left_speed?.raw})`,
            `${device.motor_right?.devicename}.run(${params.right_speed?.raw})`,
        ];
    } else if (params.left_power !== undefined && params.right_power !== undefined) {
        /* TANK-POWER mode */
        start_motor_code = [
            `${device.motor_left?.devicename}.dc(${params.left_power?.raw})`,
            `${device.motor_right?.devicename}.dc(${params.right_power?.raw})`,
        ];
    }

    return [
        ...start_motor_code,
        ...(waiting_goal ?? []),
        ...(waiting_goal ? [`${stop_fn}`] : []),
    ];
}

function _process_flippermove(
    this: PyConverter,
    op: string,
    params: FlipperMoveParams,
) {
    function _waiting_goal_creator(
        this: PyConverter,
        degrees_goal: BlockValue | undefined,
    ) {
        if (degrees_goal !== undefined) {
            return [
                `start_left, start_right = ${device.motor_left?.devicename}.angle(), ${device.motor_right?.devicename}.angle()`,
                `while max(abs(start_left - ${device.motor_left?.devicename}.angle()), abs(start_right - ${device.motor_right?.devicename}.angle())) <= ${degrees_goal?.raw}: `,
                ...indent_code(this.context.isAsyncNeeded ? 'yield' : 'pass'),
            ];
        } else if (params.unit?.value === CONST_SECONDS) {
            this.context.imports.use('pybricks.tools', 'wait');
            const seconds_goal = this.context.helpers
                .use('convert_time')
                ?.call(params.value);
            return [`${this.context.awaitPrefix}wait(${seconds_goal.raw})`];
        }
    }

    function _move_special_distance(
        this: PyConverter,
    ): [string[] | undefined, BlockValue | undefined] {
        //=== CM and INCHES
        let distance;
        let degrees_goal;
        if (params.unit?.value === CONST_CM || params.unit?.value === CONST_INCHES) {
            distance = this.context.helpers
                .use('convert_distance')
                ?.call(params.value, params.unit);
            degrees_goal = num_eval(this.context, [
                distance,
                '/',
                device.rotation_distance / 360,
            ]);
            //TODO: hoist wheel diameter setting to the start as well
        } else if (
            params.unit?.value === CONST_ROTATIONS ||
            params.unit?.value === CONST_DEGREES
        ) {
            const factor =
                (device.rotation_distance ?? 0) *
                (params.unit?.value === CONST_ROTATIONS ? 1 : 1 / 360);
            distance = num_eval(this.context, [params.value, '*', factor]);
            degrees_goal =
                params.unit?.value === CONST_ROTATIONS
                    ? num_eval(this.context, [params.value, '*', 360])
                    : params.value;
        } else {
            return [undefined, undefined];
        }

        // NOTE: we handle straight here only as a simplification, all others are handled by drive/wait/stop

        // STEERING: only handle steer:"0" here with straight -> "forward"
        if (params.steer !== undefined) {
            if (
                !params.steer.options?.is_dynamic &&
                (params.steer.toString() === '0' ||
                    params.steer.toString() === 'straight: 0')
            ) {
                params.direction = 'forward';
            }
        }
        // TANK: only handle right=left, with not-dynamic -> "forward"
        if (params.left_speed !== undefined && params.right_speed !== undefined) {
            if (
                !params.left_speed.options?.is_dynamic &&
                !params.right_speed.options?.is_dynamic &&
                params.left_speed.value === params.right_speed.value
            ) {
                params.direction = 'forward';
                params.speed = params.left_speed;
            }
        }

        // DIRECTION: forward and back
        if (params.direction !== undefined) {
            if (params.direction === 'forward' || params.direction === 'back') {
                //TODO: check speed - negate distance if negative
                direction_sign = params.direction === 'forward' ? '' : '-';
                return [
                    [
                        `${
                            this.context.awaitPrefix
                        }${d}.straight(${direction_sign}${BlockValue.raw(
                            distance,
                        )?.toString()}${postfix_then})`,
                    ],
                    undefined,
                ];
            }
        }

        // if we did not handle it above, we need to return the rotations for the next step
        return [undefined, degrees_goal];
    }

    /* POSSIBLE COMBINATIONS:
    =========================

    direction[fwd / bkw / cw / ccw] + [seconds / rots / degs / cms / inches]
    steer[-100 - 100] + [seconds / rots / degs / cms / inches]
    direction[fwd / bkw]
    steer[-100 - 100]
    [motor1 pct/motor2 pct]

    (only in old robot inventor mindstorms app)
    [motor1 pct/motor2 pct] + [seconds / rots / degs / cms / inches]
    [motor1 pct/motor2 power pct]
    steer[-100 - 100] [power pct]
    */

    const device = DeviceDriveBase.instance(this.context) as DeviceDriveBase;
    const d = device.devicename;
    params.value = params.value?.ensureNumber(this.context);

    const postfix_then = device.get_then() ? `, ${device.get_then()}` : '';
    let direction_sign = '';

    if (!device) {
        throw new Error('Device not initialized');
    }

    let waiting_goal: string[] | undefined;
    if (params.value !== undefined) {
        // -- check if we have a distance that we can handle
        const [code, degrees_goal] = _move_special_distance.call(this);
        if (code) return code;

        //-- use steering
        waiting_goal = _waiting_goal_creator.call(this, degrees_goal);
    }

    return _process_move_with_steering.call(this, params, waiting_goal);
}

function flippermove_steer(this: PyConverter, block: Block) {
    const steer = block.get('STEERING');
    const value = block.get('VALUE');
    const unit = block.get('UNIT');

    // inputs: steering, value
    return _process_flippermove.call(this, block.opcode, {
        unit,
        value,
        steer,
    });
}

function flippermoremove_steerDistanceAtSpeed(this: PyConverter, block: Block) {
    const steer = block.get('STEERING');
    const value = block.get('DISTANCE');
    const unit = block.get('UNIT');
    const speed = this.context.helpers.use('convert_speed').call(block.get('SPEED'));

    // inputs: steering, value
    return _process_flippermove.call(this, block.opcode, {
        unit,
        value,
        steer,
        speed,
    });
}

function flippermove_startSteer(this: PyConverter, block: Block) {
    const steer = block.get('STEERING');
    const direction = block.get('DIRECTION')?.toString();
    const value = block.get('DISTANCE');
    const unit = block.get('UNIT');
    const speed = block.get('SPEED')
        ? this.context.helpers.use('convert_speed').call(block.get('SPEED'))
        : undefined;
    const power = block.get('POWER');

    return _process_flippermove.call(this, block.opcode, {
        unit,
        value,
        direction,
        steer,
        speed,
        power,
    });
}

function flippermoremove_startDualSpeed(this: PyConverter, block: Block) {
    const left_speed = this.context.helpers
        .use('convert_speed')
        .call(block.get('LEFT'));
    const right_speed = this.context.helpers
        .use('convert_speed')
        .call(block.get('RIGHT'));
    const value = block.get('DISTANCE');
    const unit = block.get('UNIT');

    return _process_flippermove.call(this, block.opcode, {
        unit,
        value,
        left_speed,
        right_speed,
    });
}

function flippermoremove_startDualPower(this: PyConverter, block: Block) {
    const left_power = block.get('LEFT').ensureNumber(this.context, true);
    const right_power = block.get('RIGHT').ensureNumber(this.context, true);

    return _process_flippermove.call(this, block.opcode, { left_power, right_power });
}

function flippermoremove_movementSetStopMethod(this: PyConverter, block: Block) {
    const stop = parseInt(block.get('STOP')?.toString());
    const stop_then = calc_stop(this.context, stop);

    const device = DeviceDriveBase.instance(this.context) as DeviceDriveBase;
    const d = device.devicename;
    device.default_then = stop_then;
    return [`# setting ${d} stop at end to ${stop_then}`];
}

function flippermove_movementSpeed(this: PyConverter, block: Block) {
    const speed = block.get('SPEED');

    const device = DeviceDriveBase.instance(this.context) as DeviceDriveBase;
    // const d = device.devicename;

    const value = this.context.helpers.use('convert_speed')?.call(speed);
    return [`${device.default_speed_variable} = ${value.raw}`];
}

function flippermove_move(this: PyConverter, block: Block) {
    const direction = block.get('DIRECTION')?.toString();
    const value = block.get('VALUE');
    const unit = block.get('UNIT');

    return _process_flippermove.call(this, block.opcode, {
        unit,
        value,
        direction,
    });
}

function horizontalmove_move(this: PyConverter, block: Block, direction: string) {
    const value = block.get('ROTATIONS');
    const unit = new BlockValue(CONST_ROTATIONS, { type: ValueType.STRING });

    return _process_flippermove.call(this, block.opcode, {
        unit,
        value,
        direction,
    });
}

function flippermove_stopMove(this: PyConverter, _: Block) {
    const device = DeviceDriveBase.instance(this.context);
    const stop_fn = _get_stop_fn.call(this, device);

    return [stop_fn];
}

function flippermove_startMove(this: PyConverter, block: Block) {
    const direction = block.get('DIRECTION')?.toString();

    return _process_move_with_steering.call(this, { direction });
}

function flippermove_setDistance(this: PyConverter, block: Block) {
    const unit = block.get('UNIT');
    const distance = this.context.helpers
        .use('convert_distance')
        ?.call(block.get('DISTANCE'), unit).raw as number;

    const wheel_diameter = this.context.helpers
        .use('round')
        ?.call(distance / Math.PI, 2)
        .toFloat();
    if (!isNumber(wheel_diameter)) {
        throw new Error('Wheel diameter must be simple number');
    }

    const device = DeviceDriveBase.instance(
        this.context,
        undefined,
        wheel_diameter,
    ) as DeviceDriveBase;
    // const d = device.devicename;

    device.rotation_distance = distance;
    device.wheel_diameter = wheel_diameter;
    return [
        `# setting drivebase wheel distance to ${distance}, that is wheel diameter ${wheel_diameter} mm - this will apply for the complete code`,
    ];
}

export function initMotorPairMovementPair(
    this: PyConverter,
    block?: Block,
    pair?: string[],
    isUsed = true,
) {
    const ports = block ? block.get('PAIR')?.toString().split('') : pair;
    return DeviceDriveBase.instance(this.context, ports, undefined, undefined, isUsed);
}

function flippermove_setMovementPair(this: PyConverter, block: Block) {
    const ports = block.get('PAIR')?.toString().split('');

    // this handler is only a placeholder, initMotorPairMovementPair will only be called once in the preprocess phase
    const device = DeviceDriveBase.instance(this.context);
    return [
        `# setting drivebase motor pair to ${ports.join(
            ', ',
        )} - first one ${device.ports?.join(', ')} is applied for the complete code`,
    ];
}

function flippermoremove_movementSetAcceleration(this: PyConverter, block: Block) {
    const value = block.get('ACCELERATION').toInt(); // "200 200", default is "-1 -1", will work with parseInt simply

    // lsm:
    // version: 11-..
    // default: acceleration: "-1 -1")
    // fast:		100 100		=> 1600
    // balanced:	350 350		=> 457
    // smooth:		800 800
    // slow:		1200 1200	=> 133
    // very slow:	2000 2000	=> 80
    // ==> 160000 / value

    // llsp3:
    // version: 38
    // medium: 	3000 3000	=> 461 /6.5
    // slow: 		1000 1000	=> 153 /6.5
    // fast:		10000 10000	=> 1538 /6.5
    // ==> 10000 / 6.5

    //NOTE: handle different versions better, for now let's using the above hardcoded values to differentiate
    const value_adjusted = [1000, 3000, 10000].includes(value)
        ? Math.round(value / 6.5)
        : value === -1
        ? 800
        : Math.round(160000 / value);

    const device = DeviceDriveBase.instance(this.context);
    return [`${device.devicename}.setting(straight_acceleration=${value_adjusted})`];
}

function handleBlock(this: PyConverter, block: Block): string[] | undefined {
    switch (block.opcode) {
        case 'flippermove_setMovementPair':
            return flippermove_setMovementPair.call(this, block);
        case 'flippermoremove_steerDistanceAtSpeed':
            return flippermoremove_steerDistanceAtSpeed.call(this, block);
        case 'flippermove_setDistance':
            return flippermove_setDistance.call(this, block);
        case 'flippermove_startMove':
            return flippermove_startMove.call(this, block);
        case 'flippermove_stopMove':
        case 'horizontalmove_moveStop':
            return flippermove_stopMove.call(this, block);
        case 'flippermove_move':
            return flippermove_move.call(this, block);
        case 'flippermove_movementSpeed':
        case 'horizontalmove_moveSetSpeed':
            return flippermove_movementSpeed.call(this, block);
        case 'flippermoremove_movementSetStopMethod':
            return flippermoremove_movementSetStopMethod.call(this, block);
        case 'flippermove_startSteer':
        case 'flippermoremove_startSteerAtSpeed':
        case 'flippermoremove_startSteerAtPower':
            return flippermove_startSteer.call(this, block);
        case 'flippermoremove_startDualSpeed':
        case 'flippermoremove_moveDistanceAtSpeed':
            return flippermoremove_startDualSpeed.call(this, block);
        case 'flippermoremove_startDualPower':
            return flippermoremove_startDualPower.call(this, block);
        case 'flippermove_steer':
            return flippermove_steer.call(this, block);
        case 'horizontalmove_moveForward':
            return horizontalmove_move.call(this, block, 'forward');
        case 'horizontalmove_moveBackward':
            return horizontalmove_move.call(this, block, 'back');
        case 'horizontalmove_moveTurnClockwiseRotations':
            return horizontalmove_move.call(this, block, 'clockwise');
        case 'horizontalmove_moveTurnCounterClockwiseRotations':
            return horizontalmove_move.call(this, block, 'counterclockwise');
        case 'flippermoremove_movementSetAcceleration':
            return flippermoremove_movementSetAcceleration.call(this, block);
    }
}

// function handleOperator(_block: Block): BlockValue {
//   // Assuming there are no operator handlers based on the original code
//   return null;
// }

const handlers = {
    block: handleBlock,
    operator: undefined,
};
export default handlers;
