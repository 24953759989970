import { Block } from '../utils/block';
import { BlockValue } from '../utils/blockvalue';
import { ValueType } from '../utils/enums';
import PyConverter from '../pyconverter';

function flippersound_beep(this: PyConverter, block: Block) {
    const note = block.get('NOTE').ensureNumber(this.context, true);
    // field 'note': 48 = C, 49 = C#, 50 = D ...
    const duration = this.context.helpers
        .use('convert_time')
        // NOTE: this does not work yet - "If the duration is less than 0, then the method returns immediately and the frequency play continues to play indefinitely."
        .call(block.get('DURATION') ?? -0.001);

    // this.context.imports.use('urandom', 'randint');
    return [
        this.context.awaitPrefix +
            this.context.helpers.use('hub_speaker_flipper_play').call(note, duration)
                .raw,
    ];
}
function hub_speaker_iconblocks_play(this: PyConverter, block: Block) {
    const sound = block.get('SOUND');
    // field 'note': 48 = C, 49 = C#, 50 = D ...

    this.context.imports.use('urandom', 'randint');
    return [
        this.context.awaitPrefix +
            this.context.helpers.use('hub_speaker_iconblocks_play').call(sound).raw,
    ];
}

function flippersound_stopSound(this: PyConverter, _block: Block) {
    return [`${this.context.awaitPrefix}hub.speaker.beep(0, 0)`];
}

function sound_setvolumeto(this: PyConverter, block: Block) {
    const volume = block.get('VOLUME').ensureNumber(this.context, true);
    return [`hub.speaker.volume(${volume.raw})`];
}

function sound_changevolumeby(this: PyConverter, block: Block) {
    const volume = block.get('VOLUME').ensureNumber(this.context, true);
    return [`hub.speaker.volume(hub.speaker.volume() + ${volume.raw})`];
}

function sound_volume(this: PyConverter, _block: Block) {
    return new BlockValue('hub.speaker.volume()', {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function handleBlock(this: PyConverter, block: Block): string[] | undefined {
    switch (block.opcode) {
        case 'flippersound_beep':
        case 'flippersound_beepForTime':
            return flippersound_beep.call(this, block);
        case 'horizontalsound_playMusicSoundUntilDone':
            return hub_speaker_iconblocks_play.call(this, block);
        case 'flippersound_stopSound':
            return flippersound_stopSound.call(this, block);
        case 'sound_setvolumeto':
            return sound_setvolumeto.call(this, block);
        case 'sound_changevolumeby':
            return sound_changevolumeby.call(this, block);
    }
}

function handleOperator(this: PyConverter, block: Block): BlockValue | undefined {
    switch (block.opcode) {
        case 'sound_volume':
            return sound_volume.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
