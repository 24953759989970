import { Context } from '../context/context';
import { DeviceOnPort } from './deviceportbase';

export class DeviceMotor extends DeviceOnPort {
    _default_then?: string;
    direction_cw?: boolean;

    constructor(context: Context, port: string, direction_cw?: boolean) {
        super(context, port, 'Motor');
        this.direction_cw = direction_cw;
        this._default_then = undefined;
    }
    static override factory(context: Context, port: string, _deviceClass?: string) {
        return new DeviceMotor(context, port);
    }
    static override instance(context: Context, port: string) {
        return super.instance(context, port, 'Motor') as DeviceMotor;
    }
    get_then() {
        return this._default_then;
    }
    get default_speed_variable() {
        return `default_speeds[${this.devicename}]`;
    }
    set default_then(value: string | undefined) {
        this._default_then = value;
    }
    override get devicename() {
        return DeviceMotor.devicenameFromPort(this.context, this.port);
    }
    override setupCode() {
        const args: string[] =
            this.direction_cw !== false ? [] : ['Direction.COUNTERCLOCKWISE'];

        this.context.imports.use('pybricks.parameters', 'Direction');
        const setup_code = super._setupCode_internal(args);

        this.context.deviceDefaultSpeeds.set(
            this.devicename,
            this.context.helpers.use('convert_speed')?.call(50).toString(),
        );
        return setup_code;
    }
}
