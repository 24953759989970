import { Block } from '../utils/block';
import { BlockValue } from '../utils/blockvalue';
import {
    RegistryManager,
    RegistryPayloadWithId,
    RegistryPayloadWithParent,
    RegistryPayloadWithUse,
} from './registrymanager';
import { sanitize } from '../utils/utils';
import { Context } from './context';

type VariableRegistryPayloadIdType = string | [string, boolean];
export class VariableRegistryPayload
    implements
        RegistryPayloadWithId,
        RegistryPayloadWithParent<VariableRegistryPayload>,
        RegistryPayloadWithUse
{
    id!: VariableRegistryPayloadIdType;
    parent!: RegistryManager<VariableRegistryPayload>;
    private _value: string | BlockValue;
    private _is_list: boolean;
    private _py_name_base: string | undefined;
    private _py_name_unique: string | undefined;

    get py() {
        return this._py_name_unique;
    }

    py_setValue(block: Block, valuestr: unknown) {
        block.variablesForWriteAccess.add(this);
        return `${this.py} = ${valuestr}`;
    }

    constructor(value: string | BlockValue, is_list: boolean) {
        this._value = value;
        this._is_list = is_list;
    }

    use(..._args: unknown[]) {
        this.generateUniqueName();
    }

    generateUniqueName() {
        const name = typeof this.id === 'string' ? this.id : this.id[0];
        this._py_name_base = `g_${sanitize(name)}`;
        this._py_name_unique = ((py_name_base: string) => {
            const duplicatesCount = [...this.parent.values()].filter(
                (item) =>
                    item.payload._py_name_base === py_name_base ||
                    item.payload._py_name_unique === py_name_base,
            ).length;
            return duplicatesCount <= 1
                ? py_name_base
                : `${py_name_base}_${duplicatesCount}`;
        })(this._py_name_base);
    }

    static to_global_code(registry: RegistryManager<VariableRegistryPayload>) {
        return Array.from(registry.entries()).map(
            ([_, value]) =>
                `${value.payload._py_name_unique} = ${
                    value.payload._value || (!value.payload._is_list ? 'None' : '[]')
                }`,
        );
    }

    static createRegistry(context: Context) {
        // return new RegistryManager(
        //   (value: string | BlockValue, is_list: boolean) =>
        //     new VariableRegistryPayload(value, is_list)
        // );
        return new RegistryManager(
            context,
            (...args: unknown[]) =>
                new VariableRegistryPayload(
                    args[0] as string | BlockValue,
                    args[1] as boolean,
                ),
        );
    }
}
