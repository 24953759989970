import { Context } from '../context/context';

export class DeviceBase {
    constructor(readonly context: Context) {}
    get devicename(): string | undefined {
        return;
    }
    setupCode(): string[] {
        return [];
    }
    get dependencies(): DeviceBase[] {
        return [];
    }
    ensureDependencies() {
        // NOOP
    }
    static createRegistry(context: Context) {
        return new Map<string, DeviceBase>();
    }
}
