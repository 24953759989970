import { Block } from '../utils/block';
import { BlockValue } from '../utils/blockvalue';
import { calc_comparator } from '../utils/converters';
import { DeviceOnPort } from '../device/deviceportbase';
import { OperatorPrecedence, ValueType } from '../utils/enums';
import PyConverter from '../pyconverter';
import { CONST_AUTO_PORT } from '../utils/utils';

function flippermoresensors_deviceType(this: PyConverter, block: Block) {
    const port = DeviceOnPort.portToString(block.get('PORT')?.toString());
    return this.context.helpers.use('pupdevice_type').call(port);
}

function flippersensors_orientation(this: PyConverter, _block: Block) {
    return this.context.helpers.use('convert_hub_orientation_back')?.call(
        new BlockValue('hub.imu.up()', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        }),
    );
}

function flippersensors_isorientation(this: PyConverter, block: Block) {
    const orientation = block.get(['ORIENTATION', 'VALUE'])?.value;
    this.context.imports.use('pybricks.parameters', 'Side');
    return new BlockValue(
        `hub.imu.up() == ${
            this.context.helpers.use('convert_hub_orientation')?.call(orientation).value
        }`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}
function flippersensors_orientationAxis(this: PyConverter, block: Block) {
    const orientation = block.get('AXIS')?.toString();

    // TODO: get back to -180 - 180 tange
    if (orientation === 'yaw') {
        return new BlockValue('hub.imu.heading()', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        });
    } else if (orientation === 'pitch') {
        return new BlockValue('hub.imu.tilt()[0]', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        });
    } else if (orientation === 'roll') {
        return new BlockValue('hub.imu.tilt()[1]', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        });
    }
}

function flippersensors_color(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();
    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;

    return this.context.helpers.use('convert_color_back')?.call(
        new BlockValue(`${this.context.awaitPrefix}${d}.color()`, {
            is_dynamic: true,
            type: ValueType.OTHER,
        }),
    );
}

function flippersensors_isColor(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();
    const color = block.get(['VALUE', 'OPTION']);

    return _isColor.call(this, block, port, color);
}

function horizontalevents_whenColor(this: PyConverter, block: Block) {
    const port = CONST_AUTO_PORT;
    const color = block.get('COLOR');

    return _isColor.call(this, block, port, color);
}

function _isColor(this: PyConverter, _: Block, port: string, color1: BlockValue) {
    const color = this.context.helpers.use('convert_color')?.call(color1);

    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;
    return new BlockValue(`${this.context.awaitPrefix}${d}.color() == ${color.value}`, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
    });
}

function flippersensors_reflectivity(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();

    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;
    return new BlockValue(`${this.context.awaitPrefix}${d}.reflection()`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function flippersensors_isReflectivity(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();
    const value = block.get('VALUE');
    const comparator = calc_comparator(block.get('COMPARATOR'));
    //== comparator works OK, as the range is 0-100

    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;
    return new BlockValue(
        `${this.context.awaitPrefix}${d}.reflection() ${comparator.value} ${
            value.ensureNumber(this.context).raw
        }`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}

function sensors_isPressed(this: PyConverter, block: Block, isFullMode: boolean) {
    const port = isFullMode ? block.get('PORT')?.toString() : CONST_AUTO_PORT;
    const option = block.get('OPTION'); // pressed, hardpressed, released, pressurechanged

    const device = DeviceOnPort.instance(this.context, port, 'ForceSensor');
    const d = device?.devicename;
    switch (option?.value) {
        case 'pressed':
            return new BlockValue(`${this.context.awaitPrefix}${d}.pressed()`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        case 'released':
            return new BlockValue(`not ${this.context.awaitPrefix}${d}.pressed()`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        case 'hardpressed':
            return new BlockValue(`${this.context.awaitPrefix}${d}.pressed(10)`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        case 'pressurechanged':
            throw new Error('pressurechanged - Not implemented yet');
    }
}

function flippersensors_distance(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();
    const unit = block.get('UNIT');

    const device = DeviceOnPort.instance(this.context, port, 'UltrasonicSensor');
    const d = device?.devicename;

    return this.context.helpers.use('convert_ussensor_distance_back')?.call(
        new BlockValue(`${this.context.awaitPrefix}${d}.distance()`, {
            is_dynamic: true,
            type: ValueType.NUMBER,
        }),
        unit,
    );
}

function flippersensors_isDistance(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();
    const unit = block.get('UNIT');
    const value = block.get('VALUE');
    const comparator = block.get('COMPARATOR');

    return _isDinstance.call(this, block, port, value, unit, comparator);
}

function horizontalevents_whenCloserThan(this: PyConverter, block: Block) {
    const port = CONST_AUTO_PORT;
    const unit = new BlockValue('%', { type: ValueType.STRING });
    const value = block.get('DISTANCE');
    const comparator = new BlockValue('<');

    return _isDinstance.call(this, block, port, value, unit, comparator);
}

function _isDinstance(
    this: PyConverter,
    _: Block,
    port: string,
    value: BlockValue,
    unit: BlockValue,
    comparator: BlockValue,
) {
    const comparator1 = calc_comparator(comparator);

    const device = DeviceOnPort.instance(this.context, port, 'UltrasonicSensor');
    const d = device?.devicename;

    // NOTE: if comparator is ==, we should use range, instead of simple comparison (e.g. 1% means x>0% or y<2%)
    const distance_adjusted = this.context.helpers
        .use('convert_ussensor_distance_back')
        .call(
            new BlockValue(`${this.context.awaitPrefix}${d}.distance()`, {
                is_dynamic: true,
                type: ValueType.NUMBER,
            }),
            unit,
        );

    return new BlockValue(
        `${distance_adjusted} ${comparator1.value} ${
            value.ensureNumber(this.context).raw
        }`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}
function flippersensors_buttonIsPressed(this: PyConverter, block: Block) {
    const button = block.get('BUTTON')?.value;
    const event = block.get('EVENT')?.value; // pressed, released

    const button_enum = `Button.${button?.toString().toUpperCase()}`;
    const value = `${button_enum} in hub.buttons.pressed()`;
    return new BlockValue(event === 'pressed' ? value : `not ${value}`, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
    });
}

// function handleBlock(_block: Block): string[] {
//   // Assuming there are no block handlers based on the original code
//   return null;
// }

function handleOperator(this: PyConverter, block: Block): BlockValue | undefined {
    switch (block.opcode) {
        case 'flippermoresensors_deviceType':
            return flippermoresensors_deviceType.call(this, block);
        case 'flippersensors_orientationAxis':
            return flippersensors_orientationAxis.call(this, block);
        case 'flippersensors_orientation':
            return flippersensors_orientation.call(this, block);
        case 'flippersensors_isorientation':
        case 'flipperevents_whenOrientation':
            return flippersensors_isorientation.call(this, block);
        case 'flippersensors_color':
            return flippersensors_color.call(this, block);
        case 'flippersensors_isColor':
        case 'flipperevents_whenColor':
            return flippersensors_isColor.call(this, block);
        case 'horizontalevents_whenColor':
            return horizontalevents_whenColor.call(this, block);
        case 'flippersensors_reflectivity':
            return flippersensors_reflectivity.call(this, block);
        case 'flippersensors_isReflectivity':
        case 'flippersensors_whenReflectivity':
            return flippersensors_isReflectivity.call(this, block);
        case 'flippersensors_isPressed':
        case 'flipperevents_whenPressed':
            return sensors_isPressed.call(this, block, true);
        case 'horizontalevents_whenPressed':
            return sensors_isPressed.call(this, block, false);
        case 'flippersensors_distance':
            return flippersensors_distance.call(this, block);
        case 'flippersensors_isDistance':
        case 'flipperevents_whenDistance':
            return flippersensors_isDistance.call(this, block);
        case 'horizontalevents_whenCloserThan':
            return horizontalevents_whenCloserThan.call(this, block);
        case 'flippersensors_buttonIsPressed':
        case 'flipperevents_whenButton':
            return flippersensors_buttonIsPressed.call(this, block);
    }
}

const handlers = {
    block: undefined,
    operator: handleOperator,
};
export default handlers;
