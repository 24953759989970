import { Context } from '../context/context';
import { OperatorPrecedence, ValueType } from './enums';

export type ElemValueBaseType = string | number | boolean | undefined;
export type ElemValueType = ElemValueBaseType | BlockValue;
export class BlockValue {
    public value: ElemValueBaseType;
    constructor(
        value_in?: ElemValueType,
        public options?: {
            is_dynamic?: boolean;
            is_variable?: boolean;
            // private is_string = false,
            type?: ValueType; // ValueType.UNKNOWN;
            precedence?: OperatorPrecedence;
        },
    ) {
        if (value_in && BlockValue.is(value_in)) {
            this.value = value_in.value;
        } else {
            this.value = value_in;
        }
    }
    get is_string() {
        return this.options?.type === ValueType.STRING;
    }
    get is_numeric() {
        return (
            this.options?.type === ValueType.NUMBER ||
            (!this.options?.is_dynamic &&
                !this.is_string &&
                typeof this.value === 'number')
        );
    }
    get raw(): ElemValueBaseType {
        return !this.is_string || this.options?.is_dynamic
            ? this.value
            : `"${this.value}"`;
        //const is_string = this.is_string || typeof(this.value) !== 'number'
        //return !this.is_numeric ? this.value : `"${this.value}"`;
    }
    toString() {
        return BlockValue.toString(this);
    }
    toInt(): number {
        return BlockValue.toInt(this);
    }
    toFloat(): number {
        return BlockValue.toFloat(this);
    }
    ensureNumber(context: Context, isInteger = false) {
        return this.is_numeric
            ? this.options?.is_dynamic
                ? this
                : new BlockValue(isInteger ? this.toInt() : this.toFloat(), {
                      type: ValueType.NUMBER,
                  })
            : context.helpers.use(isInteger ? 'int_safe' : 'float_safe').call(this);
    }
    static is(value: ElemValueType): value is BlockValue {
        return value instanceof BlockValue;
    }
    static is_dynamic(value: ElemValueType): value is BlockValue {
        return BlockValue.is(value) && !!value.options?.is_dynamic;
    }
    static raw(value: ElemValueType) {
        return BlockValue.is(value) ? value.raw : value;
    }
    static value(value: ElemValueType) {
        return BlockValue.is(value) ? value.value : value;
    }
    static toString(value: ElemValueType): string {
        return BlockValue.value(value)?.toString() || '';
    }
    static toInt(value: ElemValueType): number {
        const value1 = BlockValue.is(value) ? value.value : value;
        return parseInt(value1?.toString() ?? '');
    }
    static toFloat(value: ElemValueType): number {
        const value1 = BlockValue.is(value) ? value.value : value;
        return parseFloat(value1?.toString() ?? '');
    }
    static ensureNumber(context: Context, value: ElemValueType, isInteger = false) {
        return BlockValue.is(value)
            ? value.ensureNumber(context)
            : context.helpers.use(isInteger ? 'int_safe' : 'float_safe').call(value);
    }
}

type NumEvalType =
    | ElemValueType
    | [ElemValueType]
    | [string, NumEvalType]
    | [NumEvalType, string, NumEvalType];

export function num_eval(
    context: Context,
    values: NumEvalType,
    isInteger?: boolean,
): BlockValue | undefined;
export function num_eval(
    context: Context,
    values: ElemValueType,
    isInteger?: boolean,
): BlockValue | undefined;
export function num_eval(
    context: Context,
    values: ElemValueType | NumEvalType,
    isInteger = false,
): BlockValue | undefined {
    const [a, b, c] = Array.isArray(values) ? values : [values, undefined, undefined];

    // one operand
    if (b === undefined) {
        if (Array.isArray(a)) {
            return num_eval(context, a, isInteger);
        }

        // const conv_function = isInteger ? 'int_safe' : 'float_safe';
        return !BlockValue.is_dynamic(a)
            ? new BlockValue(
                  Math.round(parseFloat(a?.toString() ?? '') * 1000) / 1000,
                  {
                      type: ValueType.NUMBER,
                  },
              )
            : !BlockValue.is(a) || a.options?.type !== ValueType.NUMBER
            ? BlockValue.ensureNumber(context, a, isInteger)
            : new BlockValue(a, {
                  type: ValueType.NUMBER,
                  is_dynamic: a.options.is_dynamic,
                  precedence: a.options.precedence,
              });
    }
    // two operands
    else if (c === undefined) {
        const b1 = num_eval(context, b, isInteger);
        const allow_local = !BlockValue.is_dynamic(b1);
        if (a === '-' || a === '+') {
            return allow_local
                ? new BlockValue((a === '-' ? -1 : +1) * BlockValue.toFloat(b1), {
                      type: ValueType.NUMBER,
                  })
                : new BlockValue(`${a}${BlockValue.raw(b1)}`, {
                      is_dynamic: true,
                      type: ValueType.NUMBER,
                      precedence: OperatorPrecedence.UNARY,
                  });
        } else if (a === 'abs') {
            if (allow_local) {
                return new BlockValue(Math.abs(BlockValue.toFloat(b1)), {
                    type: ValueType.NUMBER,
                });
            } else {
                context.imports.use('umath', null);
                return new BlockValue(`umath.fabs(${BlockValue.raw(b1)})`, {
                    is_dynamic: true,
                    type: ValueType.NUMBER,
                    precedence: OperatorPrecedence.SIMPLE,
                });
            }
        }
    }
    // three operands
    else {
        const a1 = num_eval(context, a, isInteger);
        const c1 = num_eval(context, c, isInteger);
        let a1v = BlockValue.raw(a1);
        let c1v = BlockValue.raw(c1);
        const allow_local = !BlockValue.is_dynamic(a1) && !BlockValue.is_dynamic(c1);

        if (allow_local) {
            const fnCalc = (a1: number, operator: string, c1: number) => {
                switch (operator) {
                    case '+':
                        return a1 + c1;
                    case '-':
                        return a1 - c1;
                    case '*':
                        return a1 * c1;
                    case '/':
                        return a1 / c1;
                    case '%':
                        return a1 % c1;
                }
            };

            if (a1v === undefined || b === undefined || c1v === undefined) {
                return undefined;
            }
            const value = num_eval(
                context,
                fnCalc(BlockValue.toFloat(a1v), b?.toString(), BlockValue.toFloat(c1v)),
            );

            return new BlockValue(value, {
                type: ValueType.NUMBER,
                precedence: OperatorPrecedence.SIMPLE,
            });
        } else {
            const a1 = num_eval(context, a, isInteger);
            const c1 = num_eval(context, c, isInteger);

            // optimizations, simplifications
            const operator = b?.toString();
            if (operator === '*') {
                // 0 * n = 0, n * 1 = n
                if (a1?.toString() === '0' || c1?.toString() === '1') return a1;
                // n * 0 = 0, 1 * n = n
                if (c1?.toString() === '0' || a1?.toString() === '1') return c1;
                // -1 * n = -n
                if (a1?.toString() === '-1') return num_eval(context, ['-', c1]);
                // n * -1 = -n
                if (c1?.toString() === '-1') return num_eval(context, ['-', a1]);
            } else if (operator === '/') {
                // 0 / n = 0, n / 1 = n
                if (a1?.toString() === '0' || c1?.toString() === '1') return a1;
                // n / -1 = -n
                if (c1?.toString() === '-1') return num_eval(context, ['-', a1]);
            } else if (operator === '+') {
                // n + 0 = n
                if (a1?.toString() === '0') return c1;
                // 0 + n = n
                if (c1?.toString() === '0') return a1;
            } else if (operator === '-') {
                // 0 - n = -n
                if (a1?.toString() === '0') return num_eval(context, ['-', c1]);
                // n - 0 = n
                if (c1?.toString() === '0') return a1;
            }

            const precedence = ['-', '+'].includes(b.toString())
                ? OperatorPrecedence.BINARY_ADD
                : ['*', '/', '%'].includes(b.toString())
                ? OperatorPrecedence.BINARYOP_MUL
                : OperatorPrecedence.WEAKEST;
            // undefined prededence means the highest
            if (precedence < (a1?.options?.precedence ?? 0)) {
                a1v = `(${a1v})`;
            }
            if (precedence < (c1?.options?.precedence ?? 0)) {
                c1v = `(${c1v})`;
            }

            return new BlockValue(`${a1v} ${b} ${c1v}`, {
                is_dynamic: true,
                type: ValueType.NUMBER,
                precedence,
            });
        }
    }
    return;
}
