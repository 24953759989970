// import getCurrentLine from 'get-current-line';

export const INDENT = '    ';
export const CONST_CM = 'cm';
export const CONST_INCHES = 'inches';
export const CONST_ROTATIONS = 'rotations';
export const CONST_DEGREES = 'degrees';
export const CONST_SECONDS = 'seconds';
export const CONST_AUTO_PORT = 'AUTO';

export function get_divider(text: string, prefix: string, fillchar = '-', width = 80) {
    // # ------------------------------ python example ------------------------------ #
    const len = width - text.length - 6 - prefix?.length;
    return `#${prefix} ${fillchar.repeat(
        Math.floor(len / 2),
    )} ${text} ${fillchar.repeat(Math.ceil(len / 2))} #`;
}

export function _debug(...args: unknown[]) {
    // const prefix = getCurrentLine
    //     ? (() => {
    //           const line = getCurrentLine({ frames: +3 });
    //           return `at ${line?.method} (${line?.file}:${line?.line}:${line?.char})`;
    //       })()
    //     : '';
    const prefix = '';
    console.log('::DEBUG::', ...args, prefix);
}

export function indent_code(value: string | string[], levels = 1): string[] {
    if (value === undefined) return [];
    if (Array.isArray(value)) {
        return value.reduce(
            (lines, line) => [...lines, ...indent_code(line, levels)],
            [] as string[],
        );
    } else if (value.includes('\n')) {
        return indent_code(value.split('\n'), levels);
    } else {
        return [INDENT.repeat(levels) + value];
    }
}

export function sanitize(key: string) {
    return key
        .trim()
        .toLowerCase()
        .replace(/[ .-]|^\s?\d+/gim, '_')
        .replace(
            /[áéíóöőúüű]/g,
            (c) =>
                ({
                    á: 'a',
                    é: 'e',
                    í: 'i',
                    ó: 'o',
                    ö: 'o',
                    ő: 'o',
                    ú: 'u',
                    ü: 'u',
                    ű: 'u',
                }[c] || c),
        )
        .replace(/[^a-zA-Z0-9_]/gim, '');
}

export function isNumber(value: unknown) {
    return typeof value === 'number';
}
