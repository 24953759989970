import { Context } from './context';

type RegistryEntryIdType = string | (string | number | boolean)[];
class RegistryEntry<T> {
    id: string;
    payload: T;
    constructor(id: RegistryEntryIdType, payload: T) {
        this.id = RegistryEntry.idToString(id);
        this.payload = payload;
    }
    static idToString(id?: RegistryEntryIdType) {
        return typeof id === 'string' ? id : JSON.stringify(id);
    }
}
export interface RegistryPayloadWithUse {
    use(...args: unknown[]): void;
}
export interface RegistryPayloadWithId {
    id: RegistryEntryIdType;
}
export interface RegistryPayloadWithParent<T> {
    parent: RegistryManager<T>;
}

function isRegistryPayloadWithUse(obj: unknown): obj is RegistryPayloadWithUse {
    return !!obj && typeof (obj as RegistryPayloadWithUse).use === 'function';
}
function isRegistryPayloadWithId(obj: unknown): obj is RegistryPayloadWithId {
    return !!obj && true;
}
function isRegistryPayloadWithParent<T>(
    obj: unknown,
): obj is RegistryPayloadWithParent<T> {
    return !!obj;
}

export class RegistryManager<T> {
    private registry = new Map<string, RegistryEntry<T>>();

    constructor(
        public readonly context: Context,
        private readonly factory?: (...args: unknown[]) => T,
    ) {}

    get(id?: RegistryEntryIdType): T | undefined {
        return this.registry.get(RegistryEntry.idToString(id))?.payload;
    }

    has(id: RegistryEntryIdType): boolean {
        return this.registry.has(RegistryEntry.idToString(id));
    }

    use(id: RegistryEntryIdType, ...args: unknown[]): T {
        let entry = this.registry.get(RegistryEntry.idToString(id));
        if (!entry) {
            const payload =
                typeof this.factory === 'function'
                    ? this.factory(...args)
                    : (args[0] as T);
            if (isRegistryPayloadWithId(payload)) {
                payload.id = id;
            }
            if (isRegistryPayloadWithParent(payload)) {
                payload.parent = this;
            }
            entry = new RegistryEntry<T>(id, payload);
            this.registry.set(RegistryEntry.idToString(id), entry);
        }

        if (entry.payload && isRegistryPayloadWithUse(entry.payload)) {
            entry.payload.use(...args);
        }
        return entry.payload;
    }

    entries(): [string, RegistryEntry<T>][] {
        return [...this.registry.entries()];
    }
    values(): RegistryEntry<T>[] {
        return [...this.registry.values()];
    }
    payloads(): T[] {
        return [...this.registry.values()].map((elem) => elem.payload);
    }
    clear(): void {
        this.registry.clear();
    }
}
