import { Block, BlockMatchError } from '../utils/block';
import { BlockValue } from '../utils/blockvalue';
import { ValueType } from '../utils/enums';
import PyConverter from '../pyconverter';
import { processOperation } from './operator';

function flippersensors_resetTimer(this: PyConverter, _: Block) {
    this.context.imports.use('pybricks.tools', 'StopWatch');
    this.context.variables.use('sw_main', 'StopWatch()');

    return ['sw_main.reset()'];
}

function flippercontrol_fork(this: PyConverter, block: Block) {
    function create_substack_fn(this: PyConverter, count: number, stack: Block[]) {
        const sub_code = this.process_stack(stack);
        const substack_fn = `substack${count}_fn`;
        return [substack_fn, [`def ${substack_fn}():`, ...sub_code]];
    }

    const [sub_elem_fn1, sub_elem_code1] = create_substack_fn.call(
        this,
        1,
        block.substacks[0],
    );
    const [sub_elem_fn2, sub_elem_code2] = create_substack_fn.call(
        this,
        2,
        block.substacks[1],
    );

    return [
        ...sub_elem_code1,
        ...sub_elem_code2,
        `multitask(${sub_elem_fn1}, ${sub_elem_fn2})`,
    ];
}

function flippersensors_resetYaw(this: PyConverter, _block: Block) {
    return ['hub.imu.reset_heading(0)'];
}

function event_broadcast(this: PyConverter, block: Block, do_wait: boolean) {
    const message_id = block.getInputAsShadowId('BROADCAST_INPUT');
    const message_pyname = this.context.broadcasts.get(message_id)?.get_pyname();

    return [
        `${this.context.awaitPrefix}${message_pyname}.broadcast_exec(${
            do_wait ? 'True' : 'False'
        })`,
    ];
}

function horizontalevents_broadcast(this: PyConverter, block: Block) {
    const message_id = block.get('CHOICE')?.toString();
    const message_pyname = this.context.broadcasts
        .use(message_id, message_id)
        .get_pyname();

    return [`${this.context.awaitPrefix}${message_pyname}.broadcast_exec(False)`];
}

function procedures_call(this: PyConverter, block: Block) {
    const proccode = block._block?.mutation?.proccode;
    const procdef = this.context.procedures.get(proccode);

    function defaultValueForType(type: string) {
        if (type === 'string') {
            return new BlockValue('', { type: ValueType.STRING });
        } else if (type === 'boolean') {
            return new BlockValue('False', { type: ValueType.BOOLEAN });
        }
        throw new Error(`Unknown type ${type}`);
    }

    const args = procdef
        ? [...procdef.args.values()].map((aarg) => {
              try {
                  const item = block.get(aarg.id);
                  return item ?? defaultValueForType(aarg.type);
              } catch (e) {
                  if (e instanceof BlockMatchError) {
                      const block2 = block.getBlock(aarg.id);
                      return processOperation.call(this, block2);
                  } else {
                      throw e;
                  }
              }
          })
        : undefined;

    return [
        `${this.context.awaitPrefix}${procdef?.name}(${args
            ?.map(BlockValue.raw)
            .join(', ')})`,
    ];
}

// function radiobroadcast_broadcastRadioSignalWithValueCommand(this: PyConverter, block: Block) {
//   const signal = block.get('SIGNAL');
//   const value = block.get('VALUE');

//   return [`hub.ble.broadcast(${signal.raw}+'|'+${value.raw})`];
// }

// function radiobroadcast_whenIReceiveRadioSignalHat(this: PyConverter, block: Block) {
//   const signal = block.get('SIGNAL');
//   return new BlockValue(`hub.ble.observe(1) == ${signal.raw}`, {
//     is_dynamic: true,
//     type: ValueType.BOOLEAN,
//   });
// }

function flipperevents_condition(this: PyConverter, block: Block) {
    return new BlockValue('True', {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
    });
}

function flippermoresensors_angularVelocity(this: PyConverter, block: Block) {
    const axis = block.get('AXIS')?.toString().toUpperCase();
    this.context.imports.use('pybricks.parameters', 'Axis');
    return new BlockValue(`hub.imu.angular_velocity(Axis.${axis})`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function flippermoresensors_acceleration(this: PyConverter, block: Block) {
    const axis = block.get('AXIS')?.toString().toUpperCase();
    this.context.imports.use('pybricks.parameters', 'Axis');
    return new BlockValue(`hub.imu.acceleration(Axis.${axis})`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

//TODO: check why it is device_a and not motor_a

function handleBlock(this: PyConverter, block: Block): string[] | undefined {
    switch (block.opcode) {
        case 'flippersensors_resetTimer':
            return flippersensors_resetTimer.call(this, block);
        case 'flippercontrol_fork':
            return flippercontrol_fork.call(this, block);
        case 'flippersensors_resetYaw':
            return flippersensors_resetYaw.call(this, block);
        case 'event_broadcast':
            return event_broadcast.call(this, block, false);
        case 'event_broadcastandwait':
            return event_broadcast.call(this, block, true);
        case 'horizontalevents_broadcast':
            return horizontalevents_broadcast.call(this, block);
        case 'procedures_call':
            return procedures_call.call(this, block);
        // case 'radiobroadcast_broadcastRadioSignalWithValueCommand':
        //   return radiobroadcast_broadcastRadioSignalWithValueCommand(block);
    }
}

function handleOperator(this: PyConverter, block: Block): BlockValue | undefined {
    switch (block.opcode) {
        case 'flipperevents_whenProgramStarts':
        case 'flipperhorizontalevents_when':
            return flipperevents_condition.call(this, block);
        case 'flippermoresensors_angularVelocity':
            return flippermoresensors_angularVelocity.call(this, block);
        case 'flippermoresensors_acceleration':
            return flippermoresensors_acceleration.call(this, block);
        // case 'radiobroadcast_whenIReceiveRadioSignalHat':
        //   return radiobroadcast_whenIReceiveRadioSignalHat(block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
