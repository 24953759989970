// import { createContext } from 'context';
import { BroadcastRegistryPayload } from './broadcasts';
import { DeviceBase } from '../device/devicebase';
import { HelperEnabledRegistryPayload, HelperRegistryManager } from './helpers';
import { ImportRegistryPayload } from './imports';
import { ProcedureRegistryPayload } from './procedures';
import { RegistryManager } from './registrymanager';
import { VariableRegistryPayload } from './variables';

export class Context {
    imports: RegistryManager<ImportRegistryPayload>;
    broadcasts: RegistryManager<BroadcastRegistryPayload>;
    variables: RegistryManager<VariableRegistryPayload>;
    procedures: RegistryManager<ProcedureRegistryPayload>;
    helpers: HelperRegistryManager;
    devicesRegistry: Map<string, DeviceBase>;
    deviceDefaultSpeeds: Map<string, string>;
    isAsyncNeeded = false;

    constructor() {
        this.imports = ImportRegistryPayload.createRegistry(this);
        this.broadcasts = BroadcastRegistryPayload.createRegistry(this);
        this.variables = VariableRegistryPayload.createRegistry(this);
        this.procedures = ProcedureRegistryPayload.createRegistry(this);
        this.helpers = HelperEnabledRegistryPayload.createRegistry(this);
        this.devicesRegistry = DeviceBase.createRegistry(this);
        this.deviceDefaultSpeeds = new Map<string, string>();
    }

    get awaitPrefix() {
        return this.isAsyncNeeded ? 'await ' : '';
    }
    get asyncPrefix() {
        return this.isAsyncNeeded ? 'async ' : '';
    }

    clear() {
        this.imports.clear();
        this.broadcasts.clear();
        this.variables.clear();
        this.procedures.clear();
        this.helpers.clear();
        this.devicesRegistry.clear();
        this.deviceDefaultSpeeds.clear();
        this.isAsyncNeeded = false;
    }
}

let contextInstance: Context | null = null;
export class GlobalContextManager {
    // get contextApi() {
    //     return contextWrapper;
    // }

    _getContext(): Context {
        //return contextWrapper.useX();
        if (!contextInstance) {
            contextInstance = new Context();
        }
        return contextInstance;
    }
    get imports() {
        return this._getContext().imports;
    }
    get broadcasts() {
        return this._getContext().broadcasts;
    }
    get variables() {
        return this._getContext().variables;
    }
    get procedures() {
        return this._getContext().procedures;
    }
    get helpers() {
        return this._getContext().helpers;
    }
    get devicesRegistry() {
        return this._getContext().devicesRegistry;
    }
    get deviceDefaultSpeeds() {
        return this._getContext().deviceDefaultSpeeds;
    }
    get isAsyncNeeded() {
        return this._getContext().isAsyncNeeded;
    }
    set isAsyncNeeded(value: boolean) {
        this._getContext().isAsyncNeeded = value;
    }
    get awaitPrefix() {
        return this._getContext().awaitPrefix;
    }
    get asyncPrefix() {
        return this._getContext().asyncPrefix;
    }
    clear() {
        this._getContext().clear();
    }
}

// //const contextWrapper = createContext<Context>();
// const context = new GlobalContextManager();
// export default context;
