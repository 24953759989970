import { Block } from '../utils/block';
import { BlockValue, ElemValueType, num_eval } from '../utils/blockvalue';
import { OperatorPrecedence, ValueType } from '../utils/enums';
import PyConverter from '../pyconverter';
import { _debug, sanitize } from '../utils/utils';
import { handleOperators } from './handlers';

export function processOperation(
    this: PyConverter,
    block: Block,
    returnOnEmpty = 'None',
): BlockValue {
    if (block) {
        const retval = handleOperators.call(this, block);
        if (retval) {
            return retval;
        } else {
            _debug('unknown block', block.getDescription());
        }
    }
    return new BlockValue(returnOnEmpty, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.OTHER,
    });
}

function operator_and_or(this: PyConverter, block: Block) {
    const op = block?.opcode;
    const operand1 = block?.getBlock('OPERAND1');
    const operand2 = block?.getBlock('OPERAND2');
    const code_condition1 = processOperation.call(this, operand1);
    const code_condition2 = processOperation.call(this, operand2);
    return new BlockValue(
        `(${code_condition1.raw}) ${op.includes('and') ? 'and' : 'or'} (${
            code_condition2.raw
        })`,
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.BOOLEAN,
        },
    );
}

function flipperevents_whenCondition(this: PyConverter, block: Block) {
    const block2 = block.getBlock('CONDITION');
    return processOperation.call(this, block2);
}

function flipperevents_whenTimer(this: PyConverter, block: Block) {
    const value = this.context.helpers.use('convert_time')?.call(block.get('VALUE'));

    this.context.imports.use('pybricks.tools', 'StopWatch');
    this.context.variables.use('sw_main', 'StopWatch()');

    return new BlockValue(`sw_main.time() > ${value.raw}`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
        type: ValueType.BOOLEAN,
    });
}

function flippersensors_timer(this: PyConverter, _block: Block) {
    this.context.imports.use('pybricks.tools', 'StopWatch');
    this.context.variables.use('sw_main', 'StopWatch()');

    return new BlockValue('sw_main.time()', {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.NUMBER,
    });
}

function flipperoperator_isInBetween(this: PyConverter, block: Block) {
    const value = block.get('VALUE').ensureNumber(this.context);
    const low = block.get('LOW').ensureNumber(this.context);
    const high = block.get('HIGH').ensureNumber(this.context);

    return new BlockValue(
        `(${low.raw} <= ${value.raw}) and (${value.raw} <= ${high.raw})`,
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.BOOLEAN,
        },
    );
}

function operator_contains(this: PyConverter, block: Block) {
    const string1 = this.context.helpers.use('str')?.call(block.get('STRING1'));
    const string2 = this.context.helpers.use('str')?.call(block.get('STRING2'));

    return new BlockValue(`${string1.raw}.lower().find(${string2.raw}.lower()) >= 0`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.BOOLEAN,
    });
}

function operator_length(this: PyConverter, block: Block) {
    const string = this.context.helpers.use('str')?.call(block.get('STRING'));

    return new BlockValue(`len(${string.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.NUMBER,
    });
}

function operator_letter_of(this: PyConverter, block: Block) {
    const string = this.context.helpers.use('str')?.call(block.get('STRING'));
    const letter = block.get('LETTER').ensureNumber(this.context, true);

    return new BlockValue(`${string.raw}[${letter.raw}]`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.STRING,
    });
}

function operator_join(this: PyConverter, block: Block) {
    const string1 = this.context.helpers.use('str')?.call(block.get('STRING1'));
    const string2 = this.context.helpers.use('str')?.call(block.get('STRING2'));

    return new BlockValue(`"".join([${string1.raw}, ${string2.raw}])`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.STRING,
    });
}

function operator_random(this: PyConverter, block: Block) {
    const from = block.get('FROM').ensureNumber(this.context);
    const to = block.get('TO').ensureNumber(this.context);

    this.context.imports.use('urandom', 'randint');
    return new BlockValue(`randint(${from.raw}, ${to.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.NUMBER,
    });
}

function flipperoperator_mathFunc2Params(this: PyConverter, block: Block) {
    const arg1 = block.get('ARG1').ensureNumber(this.context);
    const arg2 = block.get('ARG2').ensureNumber(this.context);
    const args = [arg1, arg2];
    const post_process_fn = (e: string) => e;
    let op2 = block.get('TYPE').value;

    switch (op2) {
        case 'pow':
        case 'atan2':
        case 'copysign':
            this.context.imports.use('umath', null);
            op2 = `umath.${op2}`;
            break;
        // NOTE: hypot missing
    }

    return new BlockValue(
        post_process_fn(`${op2}(${args.map(BlockValue.raw).join(', ')})`),
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.NUMBER,
        },
    );
}

function operator_mathop(this: PyConverter, block: Block) {
    let op2 = block.get(['TYPE', 'OPERATOR']).value;
    const num = block.get('NUM').ensureNumber(this.context);
    const args: ElemValueType[] = [num];
    let post_process_fn = (e: string) => e;

    this.context.imports.use('umath', null);
    switch (op2) {
        case 'ceiling':
            op2 = 'ceil';
            break;
        case 'ln':
            op2 = 'log';
            break;
        case 'log':
            op2 = 'log';
            post_process_fn = (e) => `${e}/umath.log(10)`;
            break;
        case 'e ^':
            op2 = 'pow';
            args.unshift('umath.e');
            break;
        case '10 ^':
            op2 = 'pow';
            args.unshift(10);
            break;
    }

    return new BlockValue(
        post_process_fn(`umath.${op2}(${args.map(BlockValue.raw).join(', ')})`),
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.NUMBER,
        },
    );
}

function operator_round(this: PyConverter, block: Block) {
    const num = block.get('NUM').ensureNumber(this.context);

    return new BlockValue(`round(${num.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.NUMBER,
    });
}

function operator_math_two_op(this: PyConverter, block: Block) {
    const operatorMap = new Map<string, string>([
        ['operator_add', '+'],
        ['operator_subtract', '-'],
        ['operator_multiply', '*'],
        ['operator_divide', '/'],
        ['operator_mod', '%'],
    ]);
    const operator = operatorMap.get(block?.opcode);
    const num1 = block.get('NUM1');
    const num2 = block.get('NUM2');

    if (!operator) {
        throw new Error('Unknown operator');
    }
    return num_eval(this.context, [num1, operator, num2]);
}

function operator_lt_gt_eq(this: PyConverter, block: Block) {
    // this is coming as string, but helper will take care of it
    // NOTE: this can be two strings and "A" > "Apple" makes sense, yet we assume numeric comparison here...
    const operand1 = block.get('OPERAND1').ensureNumber(this.context);
    const operand2 = block.get('OPERAND2').ensureNumber(this.context);
    const comparatorMap = new Map<string, string>([
        ['operator_equals', '=='],
        ['operator_lt', '<'],
        ['operator_gt', '>'],
    ]);
    const comparator = comparatorMap.get(block?.opcode);

    return new BlockValue(`${operand1.raw} ${comparator} ${operand2.raw}`, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
    });
}

function operator_not(this: PyConverter, block: Block) {
    const operand1 = block.getBlock('OPERAND');
    const code_condition = processOperation.call(this, operand1);
    return new BlockValue(`not (${code_condition.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.BOOLEAN,
    });
}

function argument_reporter_string_number_boolean(this: PyConverter, block: Block) {
    const value = sanitize(block.get('VALUE').toString());
    return new BlockValue(value, {
        is_dynamic: true,
        is_variable: true,
        precedence: OperatorPrecedence.SIMPLE,
        //type: ValueType.NUMBER,
    });
}

// function handleBlock(_block: Block): string[] {
//   // Assuming there are no block handlers based on the original code
//   return null;
// }

function handleOperator(this: PyConverter, block: Block): BlockValue | undefined {
    switch (block.opcode) {
        case 'operator_or':
        case 'operator_and':
            return operator_and_or.call(this, block);
        case 'operator_not':
            return operator_not.call(this, block);
        case 'operator_lt':
        case 'operator_gt':
        case 'operator_equals':
            return operator_lt_gt_eq.call(this, block);
        case 'operator_add':
        case 'operator_subtract':
        case 'operator_multiply':
        case 'operator_divide':
        case 'operator_mod':
            return operator_math_two_op.call(this, block);
        case 'operator_round':
            return operator_round.call(this, block);
        case 'operator_mathop':
            return operator_mathop.call(this, block);
        case 'flipperoperator_mathFunc2Params':
            return flipperoperator_mathFunc2Params.call(this, block);
        case 'operator_random':
            return operator_random.call(this, block);
        case 'operator_join':
            return operator_join.call(this, block);
        case 'operator_letter_of':
            return operator_letter_of.call(this, block);
        case 'operator_length':
            return operator_length.call(this, block);
        case 'operator_contains':
            return operator_contains.call(this, block);
        case 'flipperoperator_isInBetween':
            return flipperoperator_isInBetween.call(this, block);
        case 'flippersensors_timer':
            return flippersensors_timer.call(this, block);
        case 'flipperevents_whenTimer':
            return flipperevents_whenTimer.call(this, block);
        case 'flipperevents_whenCondition':
            return flipperevents_whenCondition.call(this, block);
        case 'argument_reporter_string_number':
        case 'argument_reporter_boolean':
            return argument_reporter_string_number_boolean.call(this, block);
    }
}

const handlers = {
    block: undefined,
    operator: handleOperator,
};
export default handlers;
