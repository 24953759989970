import { BlockValue } from './blockvalue';
import { Context } from '../context/context';

enum FLIPPERSTOP {
    COAST = 0,
    BRAKE = 1,
    HOLD = 2,
}
const flipperStopMap = new Map([
    [FLIPPERSTOP.COAST, 'Stop.COAST'],
    [FLIPPERSTOP.BRAKE, 'Stop.BRAKE'],
    [FLIPPERSTOP.HOLD, 'Stop.HOLD'],
]);

enum FLIPPERHUBORIENTATION {
    FRONT = 0,
    BACK = 1,
    TOP = 2,
    BOTTOM = 3,
    LEFT = 4,
    RIGHT = 5,
}

export const flipperHubOrientationMap = new Map([
    [FLIPPERHUBORIENTATION.FRONT, 'Side.FRONT'],
    [FLIPPERHUBORIENTATION.BACK, 'Side.BACK'],
    [FLIPPERHUBORIENTATION.TOP, 'Side.TOP'],
    [FLIPPERHUBORIENTATION.BOTTOM, 'Side.BOTTOM'],
    [FLIPPERHUBORIENTATION.LEFT, 'Side.LEFT'],
    [FLIPPERHUBORIENTATION.RIGHT, 'Side.RIGHT'],
]);

enum FLIPPERDISPLAYORIENTATION {
    TOP = 1,
    LEFT = 2,
    RIGHT = 3,
    BOTTOM = 4,
}

export const flipperDisplayOrientationMap = new Map([
    [FLIPPERDISPLAYORIENTATION.TOP, 'Side.TOP'],
    [FLIPPERDISPLAYORIENTATION.LEFT, 'Side.LEFT'],
    [FLIPPERDISPLAYORIENTATION.RIGHT, 'Side.RIGHT'],
    [FLIPPERDISPLAYORIENTATION.BOTTOM, 'Side.BOTTOM'],
]);

enum FLIPPERCOLORS {
    BLACK = 0,
    MAGENTA = 1,
    VIOLET = 2,
    BLUE = 3,
    TURQUOISE = 4,
    GREEN = 5,
    YELLOWGREEN = 6,
    YELLOW = 7,
    ORANGE = 8,
    RED = 9,
    WHITE = 10,
}
export const flipperColorsMap = new Map([
    [FLIPPERCOLORS.BLACK, 'Color.BLACK'],
    [FLIPPERCOLORS.MAGENTA, 'Color.MAGENTA'],
    [FLIPPERCOLORS.VIOLET, 'Color.VIOLET'],
    [FLIPPERCOLORS.BLUE, 'Color.BLUE'],
    [FLIPPERCOLORS.TURQUOISE, 'Color.CYAN'],
    [FLIPPERCOLORS.GREEN, 'Color.GREEN'],
    [FLIPPERCOLORS.YELLOWGREEN, 'Color.GREEN'],
    [FLIPPERCOLORS.YELLOW, 'Color.YELLOW'],
    [FLIPPERCOLORS.ORANGE, 'Color.ORANGE'],
    [FLIPPERCOLORS.RED, 'Color.RED'],
    [FLIPPERCOLORS.WHITE, 'Color.WHITE'],
]);

export function calc_stop(context: Context, value = -1) {
    context.imports.use('pybricks.parameters', 'Stop');

    if (flipperStopMap.has(value)) {
        return flipperStopMap.get(value);
    }
}

export function round2(value: number, ndigits = 0) {
    const mul = 10 ** ndigits;
    return Math.round(value * mul) / mul;
}

// no need to convert to a helper, no need for a dynamic handling, as it is a field
export function calc_comparator(value: BlockValue) {
    if (value.value === '=') {
        return new BlockValue('==');
    } else {
        return value;
    }
}
