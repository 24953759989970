import { Block } from '../utils/block';
import { BlockValue } from '../utils/blockvalue';
import PyConverter from '../pyconverter';
import { indent_code } from '../utils/utils';
import { processOperation } from './operator';

function control_forever(this: PyConverter, block: Block) {
    const sub_code = this.process_stack(block.substacks[0]);

    return ['while True:', ...sub_code];
}

function control_repeat(this: PyConverter, block: Block) {
    const times = block.get('TIMES');
    const sub_code = this.process_stack(block.substacks[0]);

    return [
        `for _ in range(${times.ensureNumber(this.context, true).raw}):`,
        ...sub_code,
    ];
}

function control_repeat_until(this: PyConverter, block: Block) {
    const condition_block = block.getBlock('CONDITION');
    // wait until block has a logical flaw, needs to have the return value from falsy to truthy
    const condition_value = processOperation.call(this, condition_block, 'True');

    const sub_code = this.process_stack(block.substacks[0]);

    return [`while not (${condition_value.raw}):`, ...sub_code];
}

function control_wait(this: PyConverter, block: Block) {
    const duration = this.context.helpers
        .use('convert_time')
        .call(block.get('DURATION').ensureNumber(this.context, true));

    this.context.imports.use('pybricks.tools', 'wait');
    return [`${this.context.awaitPrefix}wait(${duration.raw})`];
}

function control_if_and_if_else(this: PyConverter, block: Block) {
    const retval = [];
    const condition_block = block.getBlock('CONDITION');
    const condition_value = processOperation.call(this, condition_block);

    retval.push(`if ${condition_value.raw}:`);
    if (block.substacks[0]) {
        const sub_code = this.process_stack(block.substacks[0]);
        retval.push(...sub_code);
    } else {
        retval.push(...indent_code('pass'));
    }

    if (block.opcode === 'control_if_else') {
        retval.push('else:');
        const sub_code = this.process_stack(block.substacks[1]);
        retval.push(...sub_code);
    }
    return retval;
}

function control_wait_until(this: PyConverter, block: Block) {
    const condition_block = block.getBlock('CONDITION');
    // wait until block has a flaw, needs to have the return value from falsy to truthy
    const condition_value = processOperation.call(this, condition_block, 'True');

    const sub_code = this.process_stack(null); // empty substack -> results in pass

    return [`while not (${condition_value.raw}):`, ...sub_code];
}

function handleBlock(this: PyConverter, block: Block): string[] | undefined {
    switch (block.opcode) {
        case 'control_forever':
            return control_forever.call(this, block);
        case 'control_repeat':
            return control_repeat.call(this, block);
        case 'control_repeat_until':
            return control_repeat_until.call(this, block);
        case 'control_wait':
            return control_wait.call(this, block);
        case 'control_if':
        case 'control_if_else':
            return control_if_and_if_else.call(this, block);
        case 'control_wait_until':
            return control_wait_until.call(this, block);
    }
}

function handleOperator(this: PyConverter, _block: Block): BlockValue | undefined {
    // Assuming there are no operator handlers based on the original code
    return undefined;
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
