import { Block } from '../utils/block';
import { BlockValue, num_eval } from '../utils/blockvalue';
import { calc_stop } from '../utils/converters';
import { DeviceMotor } from '../device/motor';
import { OperatorPrecedence, ValueType } from '../utils/enums';
import PyConverter from '../pyconverter';
import {
    CONST_AUTO_PORT,
    CONST_DEGREES,
    CONST_ROTATIONS,
    CONST_SECONDS,
} from '../utils/utils';

function motor_motorSetSpeed(this: PyConverter, block: Block, isFullMode: boolean) {
    const port = isFullMode ? block.get('PORT')?.toString() : CONST_AUTO_PORT;
    const speed = block.get('SPEED');
    const value = this.context.helpers.use('convert_speed')?.call(speed);

    return port.split('').map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        return `${device.default_speed_variable} = ${value.raw}`;
    });
}

function flippermoremotor_motorSetStopMethod(this: PyConverter, block: Block) {
    const port = block.get('PORT').toString();
    const stop = parseInt(block.get('STOP').toString()); // hold=2, break=1, coast=0
    const stop_then = calc_stop(this.context, stop);

    return port.split('').map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        device.default_then = stop_then;
        return `# setting ${d} stop at end to ${stop_then}`;
    });
}

function horizontalmotor_motorTurnRotations(
    this: PyConverter,
    block: Block,
    direction_mul: 1 | -1,
) {
    const rotations = block.get('ROTATIONS');

    return _flippermotor_motorTurn.call(
        this,
        block,
        CONST_AUTO_PORT,
        rotations,
        direction_mul,
        new BlockValue(CONST_ROTATIONS, { type: ValueType.STRING }),
    );
}

function flippermotor_motorTurnForDirection(this: PyConverter, block: Block) {
    const port = BlockValue.toString(block.get('PORT'));

    //TODO handle multiple motor mode
    const direction_mul = block.get('DIRECTION').value === 'clockwise' ? +1 : -1;
    const value = block.get('VALUE');
    const unit = block.get('UNIT'); // rotations, degrees, seconds

    return _flippermotor_motorTurn.call(this, block, port, value, direction_mul, unit);
}

function _flippermotor_motorTurn(
    this: PyConverter,
    _: Block,
    port: string,
    value: BlockValue,
    direction_multiplier: 1 | -1,
    unit: BlockValue,
) {
    value = value.ensureNumber(this.context);

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    const postfix_then = device.get_then() ? `, ${device.get_then()}` : '';
    if (unit.value === CONST_ROTATIONS || unit.value === CONST_DEGREES) {
        const value2 = num_eval(this.context, [
            [direction_multiplier * (unit.value === CONST_ROTATIONS ? 360 : 1)],
            '*',
            value.ensureNumber(this.context),
        ]);
        return [
            `${this.context.awaitPrefix}${d}.run_angle(${device.default_speed_variable}, ${value2?.raw}${postfix_then})`,
        ];
    } else if (unit.value === CONST_SECONDS) {
        const value_adjusted = this.context.helpers.use('convert_time')?.call(value);
        return [
            `${this.context.awaitPrefix}${d}.run_time(${
                direction_multiplier > 0 ? '' : '-'
            }${device.default_speed_variable}, ${value_adjusted.raw}${postfix_then})`,
        ];
    } else {
        return undefined;
    }
}

function flippermotor_motorGoDirectionToPosition(this: PyConverter, block: Block) {
    const retval: string[] = [];
    const port = block.get('PORT').toString();
    const position = block.get('POSITION');
    const direction = block.get('DIRECTION').value; // clockwise, counterclockwise, shortest

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    if (direction === 'shortest') {
        // NOOP
    } else if (direction === 'clockwise') {
        const rotation_angle = `(${position.value} - ${d}.angle()) % 360`;
        retval.push(
            `${this.context.awaitPrefix}${d}.run_angle(${device.default_speed_variable}, ${rotation_angle}, Stop.NONE)`,
        );
    } else if (direction === 'counterclockwise') {
        const rotation_angle = `-(360 - (${position.value} - ${d}.angle() % 360))`;
        retval.push(
            `${this.context.awaitPrefix}${d}.run_angle(${device.default_speed_variable}, ${rotation_angle}, Stop.NONE)`,
        );
    }
    const postfix_then = device.get_then() ? `, ${device.get_then()}` : '';
    const value = num_eval(this.context, [
        position.ensureNumber(this.context),
        '%',
        360,
    ]);
    retval.push(
        `${this.context.awaitPrefix}${d}.run_target(${device.default_speed_variable}, ${value?.raw}${postfix_then})`,
    );

    return retval;
}

function motor_motorStop(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString() ?? CONST_AUTO_PORT;

    return port.split('').map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;

        switch (device.default_then) {
            case 'Stop.HOLD':
                return `${d}.hold()`;
            case 'Stop.COAST':
                return `${d}.stop()`;
            case 'Stop.BRAKE':
            default:
                return `${d}.brake()`;
        }
    });
}

function flippermotor_motorStartDirection(this: PyConverter, block: Block) {
    const port = block.get('PORT').toString();
    const direction = block.get('DIRECTION');
    const direction_sign = direction.value === 'clockwise' ? '' : '-';

    return port.split('').map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        return `${d}.run(${direction_sign}${device.default_speed_variable})`;
    });
}

function flippermoremotor_motorStartPower(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();
    const power = block.get('POWER');

    return port.split('').map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        return `${d}.dc(${power.raw})`;
    });
}

function flippermoremotor_motorSetDegreeCounted(this: PyConverter, block: Block) {
    const port = block.get('PORT')?.toString();
    const value = block.get('VALUE');

    // NOTE: this sets/reset both absolute and relative position, that is not the intended outcome
    // after this absolute_position will be invalid, but still we go on with this comporomse now

    return port.split('').map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        return `${d}.reset_angle(${value.raw})`;
    });
}

function flippermoremotor_position(this: PyConverter, block: Block) {
    const port = block.get('PORT').toString();

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    return this.context.helpers.use('relative_position').call(d);
}

function flippermotor_absolutePosition(this: PyConverter, block: Block) {
    const port = block.get('PORT').toString();

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    return new BlockValue(`${d}.angle()`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
        precedence: OperatorPrecedence.SIMPLE,
    });
}

function flippermotor_speed(this: PyConverter, block: Block) {
    const port = block.get('PORT').toString();

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;

    return this.context.helpers.use('convert_speed_back')?.call(
        new BlockValue(`${d}.speed()`, {
            is_dynamic: true,
            type: ValueType.NUMBER,
            precedence: OperatorPrecedence.SIMPLE,
        }),
    );
}

function flippermoremotor_power(this: PyConverter, block: Block) {
    const port = block.get('PORT').toString();

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    return new BlockValue(`${d}.load()`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
        precedence: OperatorPrecedence.SIMPLE,
    });
}

function handleBlock(this: PyConverter, block: Block): string[] | undefined {
    switch (block.opcode) {
        case 'flippermotor_motorSetSpeed':
            return motor_motorSetSpeed.call(this, block, true);
        case 'horizontalmotor_motorSetSpeed':
            return motor_motorSetSpeed.call(this, block, false);
        case 'flippermotor_motorStartDirection':
            return flippermotor_motorStartDirection.call(this, block);
        case 'flippermotor_motorStop':
        case 'horizontalmotor_motorStop':
            return motor_motorStop.call(this, block);
        case 'flippermotor_motorGoDirectionToPosition':
            return flippermotor_motorGoDirectionToPosition.call(this, block);
        case 'flippermotor_motorTurnForDirection':
            return flippermotor_motorTurnForDirection.call(this, block);
        case 'horizontalmotor_motorTurnClockwiseRotations':
            return horizontalmotor_motorTurnRotations.call(this, block, +1);
        case 'horizontalmotor_motorTurnCounterClockwiseRotations':
            return horizontalmotor_motorTurnRotations.call(this, block, -1);
        case 'flippermoremotor_motorSetStopMethod':
            return flippermoremotor_motorSetStopMethod.call(this, block);
        case 'flippermoremotor_motorStartPower':
            return flippermoremotor_motorStartPower.call(this, block);
        case 'flippermoremotor_motorSetDegreeCounted':
            return flippermoremotor_motorSetDegreeCounted.call(this, block);
    }
}

function handleOperator(this: PyConverter, block: Block): BlockValue | undefined {
    switch (block.opcode) {
        case 'flippermoremotor_position':
            return flippermoremotor_position.call(this, block);
        case 'flippermotor_absolutePosition':
            return flippermotor_absolutePosition.call(this, block);
        case 'flippermotor_speed':
            return flippermotor_speed.call(this, block);
        case 'flippermoremotor_power':
            return flippermoremotor_power.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
