import { Block } from '../utils/block';
import { BlockValue } from '../utils/blockvalue';
import control from './control';
import display from './display';
import misc from './misc';
import motor from './motor';
import motorpair from './motorpair';
import operations from './operator';
import sensor from './sensor';
import sound from './sound';
import variables from './variable';
import PyConverter from '../pyconverter';

export type BlockHandler = (block: Block) => string[] | undefined;
export type OperatorHandler = (block: Block) => BlockValue | undefined;
export interface ModuleHandlersType {
    block: BlockHandler | undefined;
    operator: OperatorHandler | undefined;
}

let _handlers: ModuleHandlersType[];
function _ensureHandlersInitialized() {
    if (!_handlers) {
        _handlers = [
            control,
            display,
            operations,
            variables,
            motor,
            motorpair,
            sensor,
            sound,
            misc,
        ];
    }
    return _handlers;
}

export function handleBlocks(this: PyConverter, block: Block) {
    _ensureHandlersInitialized();
    for (const handler of _handlers) {
        const retval = handler.block?.call(this, block);
        if (retval) {
            return retval;
        }
    }
}

export function handleOperators(this: PyConverter, block: Block) {
    _ensureHandlersInitialized();
    for (const handler of _handlers) {
        const retval = handler.operator?.call(this, block);
        if (retval) {
            return retval;
        }
    }
}
