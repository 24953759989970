import { Block } from '../utils/block';
import { BlockValue, num_eval } from '../utils/blockvalue';
import { ValueType } from '../utils/enums';
import PyConverter from '../pyconverter';

function _readParameters(
    this: PyConverter,
    block: Block,
    options: {
        variable?: boolean;
        list?: boolean;
        item?: boolean;
        index?: boolean;
        value?: boolean;
    } = {},
) {
    const item = options.item ? block.get('ITEM') : undefined;
    const variable = options.variable
        ? this.context.variables.get([block.get('VARIABLE')?.toString(), false])
        : undefined;
    const list = options.list
        ? this.context.variables.get([block.get('LIST')?.toString(), true])
        : undefined;
    const index = options.index
        ? num_eval(this.context, [(block.get('INDEX'), '-', 1)], true)
        : undefined;
    const value = options.value ? block.get('VALUE') : undefined;

    return { variable, list, index, item, value };
}

function data_setvariableto(this: PyConverter, block: Block) {
    const { variable, value } = _readParameters.call(this, block, {
        variable: true,
        value: true,
    });

    // here variable value can be string or number, keep it as is
    if (!variable?.py_setValue) {
        return;
    }
    return [variable.py_setValue(block, BlockValue.raw(value)?.toString() ?? '')];
}

function data_changevariableby(this: PyConverter, block: Block) {
    const { variable, value } = _readParameters.call(this, block, {
        variable: true,
        value: true,
    });

    const value2 = value?.options?.is_variable ? num_eval(this.context, value) : value;
    const valuestr = `${
        new BlockValue(variable?.py, {
            is_dynamic: true,
            is_variable: true,
            type: ValueType.OTHER,
        }).ensureNumber(this.context).raw
    } + ${BlockValue.raw(value2)}`;

    if (!variable?.py_setValue) {
        return;
    }
    return [variable?.py_setValue(block, valuestr)];
}

function data_addtolist(this: PyConverter, block: Block) {
    const { list, item } = _readParameters.call(this, block, {
        list: true,
        item: true,
    });

    return [`${list?.py}.append(${item?.raw})`];
}

function data_deleteoflist(this: PyConverter, block: Block) {
    const { list, index } = _readParameters.call(this, block, {
        list: true,
        index: true,
    });

    return [`del ${list?.py}[${BlockValue.raw(index)}]`];
}

function data_deletealloflist(this: PyConverter, block: Block) {
    const { list } = _readParameters.call(this, block, {
        list: true,
    });

    return [`${list?.py}.clear()`];
}

function data_insertatlist(this: PyConverter, block: Block) {
    const { list, index, item } = _readParameters.call(this, block, {
        list: true,
        index: true,
        item: true,
    });

    return [`${list?.py}.insert(${BlockValue.raw(index)}, ${item?.raw})`];
}

function data_replaceitemoflist(this: PyConverter, block: Block) {
    const { list, index, item } = _readParameters.call(this, block, {
        list: true,
        index: true,
        item: true,
    });

    return [`${list?.py}[${BlockValue.raw(index)}] = ${item?.raw}`];
}

function data_itemoflist(this: PyConverter, block: Block) {
    const { list, index } = _readParameters.call(this, block, {
        list: true,
        index: true,
    });

    return new BlockValue(`${list?.py}[${BlockValue.raw(index)}]`, {
        is_dynamic: true,
        type: ValueType.STRING,
    });
}

function data_itemnumoflist(this: PyConverter, block: Block) {
    const { list, item } = _readParameters.call(this, block, {
        list: true,
        item: true,
    });

    // TODO: add safe error handling
    return new BlockValue(`${list?.py}.index(${item?.raw}) + 1`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function data_lengthoflist(this: PyConverter, block: Block) {
    const { list } = _readParameters.call(this, block, {
        list: true,
    });

    return new BlockValue(`len(${list?.py})`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function data_listcontainsitem(this: PyConverter, block: Block) {
    const { list, item } = _readParameters.call(this, block, {
        list: true,
        item: true,
    });

    // TODO: add safe error handling
    return new BlockValue(`(${list?.py}.index(${item?.raw}) != None)`, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
    });
}

function handleBlock(this: PyConverter, block: Block): string[] | undefined {
    switch (block.opcode) {
        case 'data_setvariableto':
            return data_setvariableto.call(this, block);
        case 'data_changevariableby':
            return data_changevariableby.call(this, block);
        case 'data_addtolist':
            return data_addtolist.call(this, block);
        case 'data_deleteoflist':
            return data_deleteoflist.call(this, block);
        case 'data_deletealloflist':
            return data_deletealloflist.call(this, block);
        case 'data_insertatlist':
            return data_insertatlist.call(this, block);
        case 'data_replaceitemoflist':
            return data_replaceitemoflist.call(this, block);
    }
}

function handleOperator(this: PyConverter, block: Block): BlockValue | undefined {
    switch (block.opcode) {
        case 'data_itemoflist':
            return data_itemoflist.call(this, block);
        case 'data_itemnumoflist':
            return data_itemnumoflist.call(this, block);
        case 'data_lengthoflist':
            return data_lengthoflist.call(this, block);
        case 'data_listcontainsitem':
            return data_listcontainsitem.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
